<template>
  <div class="login">
    <TcVars />
    <div class="login__content">
      <img
        src="../assets/icons/my-sorgenia-logo.svg"
        width="203"
        height="56px"
      />
      <div class="login__box">
        <template v-if="!requestSent">
          <div class="login__title">Recupero password</div>
          <div class="login__subTitle mb-3">
            Inserisci il Nome utente con cui accedi alla tua Area Personale e
            all'App MySorgenia e l'Email che hai utilizzato per registrarti.
          </div>
          <div class="login__subTitle">
            <strong
              >Attenzione! Nome utente e Email potrebbero coincidere.</strong
            >
          </div>
          <div class="login__auth">
            <v-text-field
              v-model.trim="username"
              @blur="v$.username.$touch()"
              label="NOME UTENTE / EMAIL"
              clearable
              class="auth__input"
              :error-messages="usernameErrors"
            ></v-text-field>
            <v-text-field
              v-model.trim="email"
              @blur="v$.email.$touch()"
              label="EMAIL"
              clearable
              class="auth__input"
              :error-messages="emailErrors"
            ></v-text-field>
          </div>
          <div class="login__error">
            <span class="red--text">{{ errorMessage }}</span>
          </div>
          <div class="login__btn">
            <v-btn
              class="white--text auth__btn"
              color="primary"
              width="222"
              height="54"
              :rounded="true"
              @click="requestResetPwd"
              :disabled="v$.$invalid"
            >
              Prosegui
            </v-btn>
          </div>
          <router-link to="retrieveCredentials">
            <h5 class="auth__credential color--blue">
              Non ricordi le tue credenziali?
            </h5>
          </router-link>
        </template>
        <template v-else>
          <div class="login__title">
            Ti abbiamo inviato una password provvisoria
          </div>
          <div class="login__subTitle">
            Controlla la tua email. Ti abbiamo invito una password provvisoria
            all'indirizzo che hai indicato. Usala per accedere all'<strong
              >Area Personale MySorgenia</strong
            >
            e impostare la tua nuova password.
          </div>
          <div class="login__auth justify-center d-flex">
            <v-btn
              class="white--text auth__btn"
              color="primary"
              width="156"
              height="54"
              :rounded="true"
              @click="goToLogin"
            >
              Prosegui
            </v-btn>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import TcVars from '@/components/tcTracker/TcVars'
import { email, required } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import router from '../router/router'
// import GenErr from '@/js/genericErrors'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      username: '',
      email: '',
      requestSent: false,
      errorMessage: '',
      errorMessages: {
        NO_VALID_EMAIL: 'Email non vailda',
        NO_MACTH: 'Nome utente ed email non corrispondono. Verificare entrambi',
        UNKNOWN_ERROR:
          'Si è verificato un errore imprevisto durante il recupero password. Ti chiediamo di riprovare.',
        SERVER_ERROR:
          'Si è verificato un errore imprevisto durante il recupero password. Ti chiediamo di riprovare.',
        MAX_RETRIES:
          'Impossibile contattare il server: la sessione verrà interrotta.\nRiprova facendo un nuovo login.'
      }
    }
  },
  components: {
    TcVars
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem('token')) {
      router.push({ name: 'informationProfile' })
    }
    next()
  },
  computed: {
    usernameErrors() {
      let errArray = []

      if (!this.v$.username.$dirty) return errArray

      this.v$.username.required.$invalid === true &&
        errArray.push('Il nome utente è obbligatorio')
      this.errorMessage !== '' && errArray.push('')
      return errArray
    },
    emailErrors() {
      let errArray = []

      if (!this.v$.email.$dirty) return errArray

      this.v$.email.email.$invalid === true &&
        errArray.push('Formato email non valido')
      this.v$.email.required.$invalid === true &&
        errArray.push("L'email è obbligatoria")
      this.errorMessage !== '' && errArray.push('')
      return errArray
    }
  },
  methods: {
    ...mapActions('session', {
      resetpwdService: 'resetpwd'
    }),
    async requestResetPwd() {
      this.errorMessage = ''
      try {
        await this.resetpwdService({
          username: this.username,
          email: this.email
        })
        this.requestSent = true
      } catch (error) {
        console.error('Reset Pwd error' + error)
        this.errorMessage = this.errorMessages[error.message]
      }
    },
    goToLogin() {
      router.push({ name: 'login' })
    }
  },
  validations: {
    username: { required },
    email: { required, email }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/authentication/auth.scss';
</style>
