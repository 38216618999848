<template>
  <div class="flow std-side-padding mb-12 pt-10">
    <TcVars />
    <div class="stepFlow">
      <div class="step">
        <div class="label-step complete">OFFERTA</div>
        <div :class="`circle ${step > 0 ? 'complete' : ''}`">
          <span>1</span>
        </div>
        <v-progress-linear
          :value="65 * (step + 1)"
          color="#5EB83D"
          background-color="transparent"
        ></v-progress-linear>
      </div>
      <div class="step">
        <div :class="`label-step ${step >= 1 ? 'complete' : ''}`">DATI</div>
        <div :class="`circle ${step > 1 ? 'complete' : ''}`">
          <span>2</span>
        </div>
        <v-progress-linear
          :value="step >= 1 ? 65 * step : 0"
          color="#5EB83D"
          background-color="transparent"
        ></v-progress-linear>
      </div>
      <div class="step">
        <div :class="`label-step ${step >= 2 ? 'complete' : ''}`">CONFERMA</div>
        <div :class="`circle ${step > 2 ? 'complete' : ''}`">
          <span>3</span>
        </div>
        <v-progress-linear
          :value="step >= 2 ? 65 * (step - 1) : 0"
          color="#5EB83D"
          background-color="transparent"
        ></v-progress-linear>
      </div>
    </div>
    <h5 class="flow__back mb-6" v-if="step > 0" @click="prevStep">
      {{ title }}
    </h5>
    <Offer v-if="step === 0" @nextStep="nextStep" />
    <DataOffer v-if="step === 1" @nextStep="nextStep" />
    <Check v-if="step >= 2" @nextStep="nextStep" />
  </div>
</template>
<script>
import Offer from './Offer.vue'
import DataOffer from './Data.vue'
import Check from './Check.vue'
import TcVars from '@/components/tcTracker/TcVars'
import { mapGetters } from 'vuex'

export default {
  name: 'changeOffer',
  data() {
    return {
      step: 0,
      historyStep: [{ step: 0, trackEventName: '' }]
    }
  },
  computed: {
    ...mapGetters('offers', ['changeProduct']),
    title() {
      return this.changeProduct?.title
    }
  },

  methods: {
    nextStep() {
      this.step++
      this.historyStep.push(this.step)
    },
    prevStep() {
      this.step--
      this.historyStep.pop(1)
    }
  },
  components: {
    Offer,
    DataOffer,
    Check,
    TcVars
  }
}
</script>
<style lang="scss" scoped>
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.9);
  }
  66% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.flow {
  border-top: 1px solid darkgray;
  .stepFlow {
    margin-bottom: 55px;
  }
  &__back {
    margin-top: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;
    h2 {
      font-weight: 500;
      color: black;
      cursor: pointer;
    }
    &::before {
      content: '';
      background-image: url('../../../assets/ic-chevron-sx-blue.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px;
      width: 20px;
      height: 24px;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  .v-progress-linear {
    position: absolute;
    top: 15px;
    left: 0;
  }
  .stepFlow {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .step {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      text-align: right;
      position: relative;
      &.hidden {
        opacity: 0;
      }
      .label-step {
        color: #ccd0e1;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: normal;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -10px;
        &.complete {
          color: black;
        }
      }
      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 21px;
        font-weight: 500;
        color: #ccd0e1;
        z-index: 1;
        background: white;
        border: 2px solid;
        border-color: #ccd0e1;
        transition: all 0.5s ease;
        span {
          opacity: 1;
          transition: opacity 0.2s ease;
        }
        &::after {
          content: url('../../../assets/ic-check-white.svg');
          position: absolute;
          top: 2px;
          opacity: 0;
          transition: opacity 0.1s ease;
        }
        &.complete {
          animation: bounce 0.5s ease-in-out;
          animation-delay: 0.2s;
          background-color: #5eb83d;
          border-color: #5eb83d;
          color: #fff;
          position: relative;
          span {
            opacity: 0;
            transition: opacity 0.1s ease;
          }
          &::after {
            opacity: 1;
          }
        }
      }
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        background: #ccd0e1;
        top: 15px;
        left: 0;
        z-index: 0;
      }
    }
  }
}
</style>
