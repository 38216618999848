import { render, staticRenderFns } from "./Placeholder.vue?vue&type=template&id=0b66f18c&scoped=true"
import script from "./Placeholder.vue?vue&type=script&lang=js"
export * from "./Placeholder.vue?vue&type=script&lang=js"
import style0 from "./Placeholder.vue?vue&type=style&index=0&id=0b66f18c&prod&lang=scss&scoped=true"
import style1 from "./Placeholder.vue?vue&type=style&index=1&id=0b66f18c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b66f18c",
  null
  
)

export default component.exports