<template>
  <div :class="`placeholder p-${type}`">
    <content-loader
      v-if="type === 'supply'"
      :width="748"
      :height="89"
      :speed="2"
      primaryColor="#b7bfd2"
      secondaryColor="#ccc"
    >
      <rect x="32" y="30" rx="2" ry="2" width="32" height="32" />
      <rect x="80" y="30" rx="2" ry="2" width="130" height="12" />
      <rect x="80" y="49" rx="2" ry="2" width="130" height="12" />
    </content-loader>
    <content-loader
      v-else-if="type === 'offer'"
      :width="360"
      :height="305"
      :speed="2"
      primaryColor="#b7bfd2"
      secondaryColor="#ccc"
    >
      <rect x="24" y="30" rx="2" ry="2" width="32" height="32" />
      <rect x="72" y="39" rx="2" ry="2" width="130" height="12" />
      <rect x="24" y="90" rx="2" ry="2" width="312" height="12" />
      <rect x="24" y="117" rx="2" ry="2" width="312" height="41" />
      <rect x="24" y="173" rx="2" ry="2" width="312" height="41" />
      <rect x="24" y="229" rx="2" ry="2" width="312" height="41" />
    </content-loader>
    <content-loader
      v-else-if="type === 'card'"
      :width="261"
      :height="199"
      :speed="2"
      primaryColor="#b7bfd2"
      secondaryColor="#ccc"
    >
      <rect x="24" y="117" rx="2" ry="2" width="110" height="14" />
      <rect x="24" y="139" rx="2" ry="2" width="81" height="14" />
      <rect x="24" y="161" rx="2" ry="2" width="122" height="14" />
    </content-loader>
    <content-loader
      v-else-if="type === 'promo'"
      :width="width"
      :height="height"
      :speed="2"
      primaryColor="#b7bfd2"
      secondaryColor="#ccc"
    >
      <rect x="48" y="175" rx="2" ry="2" width="130" height="17" />
      <rect x="48" y="201" rx="2" ry="2" width="95" height="17" />
    </content-loader>
    <content-loader
      v-else-if="type === 'changeProductHeader'"
      :width="652"
      :height="133"
      :speed="2"
      primaryColor="#eaeef5"
    >
      <rect x="0.130859" y="0.722656" width="652" height="32" rx="2" />
      <rect x="0.130859" y="43.7227" width="22" height="22" rx="2" />
      <rect x="30.1309" y="43.7227" width="296" height="22" rx="2" />
      <rect x="0.130859" y="77.7227" width="326" height="22" rx="2" />
      <rect x="0.130859" y="111.723" width="326" height="22" rx="2" />
      <rect x="0.130859" y="157.723" width="652" height="167" rx="16" />
    </content-loader>
    <content-loader
      v-else-if="type === 'changeProductBody'"
      :width="652"
      :height="167"
      :speed="2"
      :primaryColor="'#b7bfd2'"
    >
      <rect x="16.6641" y="16.5" width="100" height="28" rx="2" />
      <rect x="16.6641" y="52.5" width="200" height="32" rx="2" />
      <rect x="604.664" y="52.5" width="32" height="32" rx="2" />
      <rect x="16.6641" y="103.5" width="620" height="16" rx="2" />
      <rect x="16.6641" y="135.5" width="620" height="16" rx="2" />
    </content-loader>
    <content-loader
      v-else-if="type === 'changeProductFooter'"
      :width="652"
      :height="190"
      :speed="2"
      primaryColor="#eaeef5"
    >
      <rect x="2" y="9" rx="2" ry="2" width="146" height="16" />
      <rect x="2" y="49" rx="2" ry="2" width="652" height="16" />
      <rect x="2" y="89" rx="2" ry="2" width="652" height="16" />
      <rect x="2" y="129" rx="2" ry="2" width="652" height="16" />
      <rect x="2" y="169" rx="2" ry="2" width="652" height="16" />
    </content-loader>
    <content-loader
      v-else-if="type === 'upsellingAnteprima'"
      :width="652"
      :height="462"
      :speed="2"
      :primaryColor="'#b7bfd2'"
    >
      <rect x="16" y="16.7178" width="400" height="24" rx="2" />
      <rect x="16" y="56.7178" width="400" height="40" rx="2" />
      <rect x="612" y="16.7178" width="24" height="24" rx="2" />
      <rect x="16" y="172.718" width="200" height="16" rx="2" />
      <rect x="16" y="212.718" width="620" height="16" rx="2" />
      <rect x="16" y="252.718" width="620" height="16" rx="2" />
      <rect x="16" y="292.718" width="620" height="16" rx="2" />
      <rect x="16" y="332.718" width="620" height="16" rx="2" />
      <rect x="16" y="372.718" width="620" height="16" rx="2" />
    </content-loader>
    <content-loader
      v-else-if="type === 'upsellingAnteprimaNoBg'"
      :width="310.5"
      :height="16"
      :speed="2"
      :primaryColor="'#eaeef5'"
    >
      <rect width="310.5" height="16" rx="2" />
    </content-loader>
    <content-loader
      v-else-if="type === 'upsellingPreventivo'"
      :width="652"
      :height="462"
      :speed="2"
      :primaryColor="'#b7bfd2'"
    >
      <rect x="16.6641" y="16.5" width="100" height="28" rx="2" />
      <rect x="16.6641" y="52.5" width="200" height="32" rx="2" />
      <rect x="604.664" y="52.5" width="32" height="32" rx="2" />
      <rect x="16.6641" y="103.5" width="620" height="16" rx="2" />
      <rect x="16.6641" y="135.5" width="620" height="16" rx="2" />
    </content-loader>
    <content-loader
      v-else-if="type === 'upsellingPreventivoNoBg'"
      :width="652"
      :height="220"
      :speed="2"
      :primaryColor="'#eaeef5'"
    >
      <rect x="2" y="9" rx="2" ry="2" width="146" height="16" />
      <rect x="2" y="49" rx="2" ry="2" width="652" height="16" />
      <rect x="2" y="89" rx="2" ry="2" width="652" height="16" />
      <rect x="2" y="129" rx="2" ry="2" width="652" height="16" />
      <rect x="2" y="169" rx="2" ry="2" width="652" height="16" />
    </content-loader>
    <div class="mt-6" v-else-if="type === 'photovoltaic'">
      <div class="mb-6">
        <content-loader
          :width="1140"
          :height="60"
          :speed="2"
          primaryColor="#b7bfd2"
          secondaryColor="#ccc"
        >
          <rect x="32" y="0" rx="8" ry="8" width="1082" height="40" />
        </content-loader>
        <content-loader
          :width="1140"
          :height="90"
          :speed="2"
          primaryColor="#eaeef5"
          secondaryColor="#ccc"
        >
          <rect x="32" y="0" rx="8" ry="8" width="1082" height="88" />
        </content-loader>
      </div>
      <div class="photovoltaicCard">
        <div>
          <content-loader
            :width="360"
            :height="350"
            :speed="2"
            primaryColor="#eaeef5"
            secondaryColor="#ccc"
          >
            <rect x="32" y="0" width="347" height="350" />
          </content-loader>
        </div>
        <div class="photovoltaicCardBg">
          <content-loader
            :width="360"
            :height="350"
            :speed="2"
            primaryColor="#b7bfd2"
            secondaryColor="#ccc"
          >
            <rect x="15" y="15" rx="2" ry="2" width="40" height="40" />
            <rect x="15" y="71" rx="2" ry="2" width="145" height="24" />
            <rect x="15" y="111" rx="2" ry="2" width="145" height="40" />
            <rect x="15" y="207" rx="2" ry="2" width="321" height="16" />
            <rect x="15" y="255" rx="2" ry="2" width="321" height="16" />
            <rect x="15" y="303" rx="2" ry="2" width="321" height="16" />
          </content-loader>
        </div>
        <div class="photovoltaicCardBg">
          <content-loader
            :width="360"
            :height="350"
            :speed="2"
            primaryColor="#b7bfd2"
            secondaryColor="#ccc"
          >
            <rect x="15" y="15" rx="2" ry="2" width="40" height="40" />
            <rect x="15" y="71" rx="2" ry="2" width="145" height="24" />
            <rect x="15" y="111" rx="2" ry="2" width="145" height="40" />
            <rect x="15" y="207" rx="2" ry="2" width="321" height="16" />
            <rect x="15" y="255" rx="2" ry="2" width="321" height="16" />
            <rect x="15" y="303" rx="2" ry="2" width="321" height="16" />
          </content-loader>
        </div>
      </div>
      <div class="skeletonFooter">
        <div class="littleCardSection">
          <div class="littleCard">
            <content-loader
              :width="80"
              :height="50"
              :speed="2"
              primaryColor="#b7bfd2"
              secondaryColor="#ccc"
            >
              <rect x="0" y="2" rx="2" ry="2" width="77" height="24" />
              <rect x="0" y="34" rx="2" ry="2" width="77" height="8" />
            </content-loader>
          </div>
          <div class="littleCard">
            <content-loader
              :width="80"
              :height="50"
              :speed="2"
              primaryColor="#b7bfd2"
              secondaryColor="#ccc"
            >
              <rect x="0" y="2" rx="2" ry="2" width="77" height="24" />
              <rect x="0" y="34" rx="2" ry="2" width="77" height="8" />
            </content-loader>
          </div>
          <div class="littleCard">
            <content-loader
              :width="80"
              :height="50"
              :speed="2"
              primaryColor="#b7bfd2"
              secondaryColor="#ccc"
            >
              <rect x="0" y="2" rx="2" ry="2" width="77" height="24" />
              <rect x="0" y="34" rx="2" ry="2" width="77" height="8" />
            </content-loader>
          </div>
        </div>

        <div class="noBg">
          <content-loader
            :width="800"
            :height="90"
            :speed="2"
            primaryColor="#eaeef5"
            secondaryColor="#ccc"
          >
            <rect x="0" y="0" rx="8" ry="8" width="735" height="78" />
          </content-loader>
        </div>
      </div>
    </div>
    <div class="" v-else-if="type === 'pundetail'">
      <content-loader
        :width="1142"
        :height="23"
        :speed="2"
        :primaryColor="'#eaeef5'"
      >
        <rect x="0.5" width="80" height="22" rx="2" />
        <rect x="90.5" width="22" height="22" rx="2" />
        <rect x="122.5" width="80" height="22" rx="2" />
      </content-loader>
      <content-loader
        class="mt-2"
        :width="1142"
        :height="52"
        :speed="2"
        :primaryColor="'#eaeef5'"
      >
        <rect x="0.5" width="558" height="52" rx="2" fill="#EAEEF5" />
      </content-loader>
      <content-loader
        :width="1145"
        :height="29"
        :speed="2"
        :primaryColor="'#eaeef5'"
      >
        <rect x="0.5" width="1145" height="28" rx="2" />
      </content-loader>
      <content-loader
        class="mt-8"
        :width="1145"
        :height="29"
        :speed="2"
        :primaryColor="'#eaeef5'"
      >
        <rect width="220" height="24" rx="2" />
      </content-loader>
      <div class="punCharts">
        <div class="mt-3 mr-3">
          <div class="titlePunChart">
            <content-loader
              :width="220"
              :height="23"
              :speed="2"
              primaryColor="#b7bfd2"
              secondaryColor="#ccc"
            >
              <rect width="220" height="22" rx="2" fill="#B7BFD2" />
            </content-loader>
          </div>
          <div class="gridChart mt-3">
            <content-loader
              :width="510"
              :height="233"
              :speed="2"
              primaryColor="#b7bfd2"
              secondaryColor="#ccc"
            >
              <rect width="510" height="232" rx="2" />
            </content-loader>
            <content-loader
              class="mt-4"
              :width="510"
              :height="25"
              :speed="2"
              primaryColor="#b7bfd2"
              secondaryColor="#ccc"
            >
              <rect width="510" height="24" rx="2" />
            </content-loader>
          </div>
        </div>
        <div class="mt-3 ml-3">
          <div class="titlePunChart">
            <content-loader
              :width="220"
              :height="23"
              :speed="2"
              primaryColor="#b7bfd2"
              secondaryColor="#ccc"
            >
              <rect width="220" height="22" rx="2" fill="#B7BFD2" />
            </content-loader>
          </div>
          <div class="gridChart mt-3">
            <content-loader
              :width="510"
              :height="233"
              :speed="2"
              primaryColor="#b7bfd2"
              secondaryColor="#ccc"
            >
              <rect width="510" height="232" rx="2" />
            </content-loader>
            <content-loader
              class="mt-4"
              :width="510"
              :height="25"
              :speed="2"
              primaryColor="#b7bfd2"
              secondaryColor="#ccc"
            >
              <rect width="510" height="24" rx="2" />
            </content-loader>
          </div>
        </div>
      </div>
      <div class="legendPun mt-7">
        <content-loader
          :width="1142"
          :height="41"
          :speed="2"
          :primaryColor="'#eaeef5'"
        >
          <rect width="300" x="420" height="40" rx="2" />
        </content-loader>
      </div>
    </div>
    <div class="" v-else-if="type === 'commodityOffer'">
      <div class="offerSection">
        <content-loader
          :width="652"
          :height="375"
          :speed="2"
          :primaryColor="'#dfe4ef'"
        >
          <rect x="16" y="16.7178" width="100" height="24" rx="2" />
          <rect x="16" y="56.7178" width="200" height="32" rx="2" />
          <rect x="604" y="56.7178" width="32" height="32" rx="2" />

          <rect x="16" y="112.718" width="620" height="16" rx="2" />
          <rect x="16" y="136.718" width="620" height="16" rx="2" />

          <rect x="16" y="176.718" width="620" height="16" rx="2" />
          <rect x="16" y="200.718" width="620" height="16" rx="2" />

          <rect x="16" y="240.718" width="620" height="16" rx="2" />
          <rect x="16" y="264.718" width="620" height="16" rx="2" />

          <rect x="16" y="302.718" width="169" height="43" rx="2" />
        </content-loader>
      </div>
      <div class="noBgCommodityOffer mt-3">
        <content-loader
          :width="652"
          :height="179"
          :speed="2"
          :primaryColor="'#eaeef5'"
        >
          <rect x="2" y="9" rx="2" ry="2" width="146" height="16" />
          <rect x="2" y="44" rx="2" ry="2" width="652" height="16" />
          <rect x="2" y="84" rx="2" ry="2" width="652" height="16" />
          <rect x="2" y="124" rx="2" ry="2" width="652" height="16" />
          <rect x="2" y="164" rx="2" ry="2" width="652" height="16" />
        </content-loader>
      </div>
    </div>
  </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'
const width = (document.body.clientWidth * 33) / 100 - 10
const height = (width * 48) / 100
export default {
  name: 'placeholderComponent',
  props: {
    type: { type: String, default: 'supply' }
  },
  data() {
    return {
      width: width,
      height: height
    }
  },
  components: {
    ContentLoader
  }
}
</script>
<style lang="scss" scoped>
.placeholder {
  background: #eaeef5;
  border-radius: 8px;
  margin-bottom: 12px;
  &.p-card {
    border-radius: initial;
    margin-bottom: 0;
  }
  &.p-promo {
    width: calc(33% - 5px);
    height: 100%;
    margin-bottom: 0px;
  }
  &.p-upsellingAnteprima {
    width: 652px;
    height: 406px;
    margin-bottom: 0px;
  }
  &.p-upsellingAnteprimaNoBg {
    background: none;
    width: 310px;
    margin-bottom: 0px;
    margin-top: 36px;
  }
  &.p-upsellingPreventivo {
    width: 652px;
    height: 168px;
    margin-bottom: 0px;
  }
  &.p-upsellingPreventivoNoBg {
    background: none;
    width: 652px;
    margin-bottom: 0px;
    margin-top: 24px;
  }
  &.p-changeProductBody {
    width: 100%;
    height: 167px;
    margin-bottom: 22px;
  }
  &.p-changeProductFooter {
    background: none;
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  }
  &.p-changeProductHeader {
    background: none;
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
  }
  &.p-commodityOffer {
    background: none;
    width: 652px;
    height: 607px;
    margin-bottom: 0px;
    .offerSection {
      background: #f8f9fc;
      width: 652px;
      height: 370px;
      border-radius: 16px;
    }
    .noBgCommodityOffer {
      height: 176px;
      width: 652px;
    }
  }
  &.p-photovoltaic {
    background: #f8f9fc;
    border-radius: 30px;
    width: 1140px;
    margin-bottom: 30px;
    height: 681px;
  }
  .photovoltaicCard {
    width: 1114px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    div {
      width: 360px;
      height: 350px;
    }
  }
  .photovoltaicCardBg {
    background: #eaeef5;
    border-radius: 3%;
  }
  .skeletonFooter {
    width: 1140px;
    display: flex;
    justify-content: space-between;
  }
  .littleCard {
    width: 93px;
    padding: 10px;
    padding-top: 20px;
    height: 79px;
    background: #eaeef5;
    border-radius: 6%;
  }
  .noBg {
    padding: 0;
    width: 780px;
    background: none;
    svg {
      width: 840px;
      height: 90px;
    }
  }
  .littleCardSection {
    display: flex;
    justify-content: space-between;
    width: 326px;
    margin-left: 32px;
  }
  &.p-pundetail {
    background: none;
    border-radius: 8px;
    margin-bottom: 32px;
  }
  .titlePunChart {
    background: #eaeef5;
    width: 558px;
    height: 54px;
    border-radius: 8px;
    margin-bottom: 22px;
    svg {
      width: 40%;
      margin-left: 23px;
      margin-top: 17px;
    }
  }
  .gridChart {
    background: #eaeef5;
    width: 558px;
    height: 328px;
    border-radius: 8px;
    padding: 24px;
    svg {
      width: 100%;
      margin: auto;
    }
  }
  .punCharts {
    display: flex;
    align-items: center;
  }
  .legendPun {
    width: 100%;
  }
}
</style>
<style lang="scss">
.fade-enter-active {
  transition: opacity 500ms ease-in-out;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-active {
  display: none;
}
</style>
