<template>
  <div
    class="profile"
    :class="[
      firstVisit ? `step-${currentIndex}` : '',
      firstVisit ? 'firstVisit' : ''
    ]"
  >
    <div v-if="firstVisit" class="firstVisitOverlay"></div>
    <div class="profile__header std-side-padding">
      <v-breadcrumbs
        :items="breadcrumbsItems"
        divider=">"
        class="itemsWhite py-0 mb-10"
      >
        <template v-slot:divider>
          <img src="@/assets/icons/chevron-dx-white.svg" width="24" />
        </template>
      </v-breadcrumbs>
      <div class="profile__title">Il mio profilo</div>
    </div>
    <div class="profile__content std-side-padding-profile">
      <div class="profile__sidemenu">
        <div class="d-flex align-center mb-8" @click="selectProfile">
          <h5 class="menuName">
            {{ clientSelected.firstName }} {{ clientSelected.lastName }}
          </h5>
          <img
            v-if="Object.keys(accountClients).length > 1"
            class="ml-3"
            src="../../assets/icons/chevron.svg"
          />
        </div>
        <div
          v-if="filteredClientDetails.clientOwnerCode"
          class="LabelL menuItems"
          @click="$router.push({ name: 'consumptionProfile' }).catch(() => {})"
          :class="{
            underline: pageSelected === 'consumptionProfile',
            underlineGrey: pageSelected !== 'consumptionProfile'
          }"
        >
          Le mie abitudini di consumo
        </div>
        <div
          class="LabelL menuItems"
          @click="$router.push({ name: 'informationProfile' }).catch(() => {})"
          :class="{
            underline: pageSelected === 'informationProfile',
            underlineGrey: pageSelected !== 'informationProfile'
          }"
        >
          Dati personali
        </div>
        <div
          class="LabelL menuItems"
          @click="$router.push({ name: 'documentsProfile' }).catch(() => {})"
          :class="{
            underline: pageSelected === 'documentsProfile',
            underlineGrey: pageSelected !== 'documentsProfile'
          }"
        >
          Contratti e documenti
        </div>
        <div
          class="LabelL menuItems"
          @click="$router.push({ name: 'informativeProfile' }).catch(() => {})"
          :class="{
            underline: pageSelected === 'informativeProfile',
            underlineGrey: pageSelected !== 'informativeProfile'
          }"
        >
          Informative e consensi
        </div>

        <div class="d-flex justify-center">
          <MYSButton
            buttonText="Log-out"
            buttonClass="bold primaryInvertedButtonSBorded mt-6"
            @buttonClick="logoutUser"
          />
        </div>
      </div>
      <router-view></router-view>
    </div>
    <v-dialog v-model="dialogUser" max-width="555">
      <div class="dialogSurvey">
        <div class="d-flex justify-space-between align-center mb-6">
          <h3>Cambia profilo</h3>
          <img
            class="close"
            src="../../assets/icons/ic-close.svg"
            @click="dialogUser = false"
          />
        </div>
        <div
          v-for="(client, i) in accountClients"
          :key="i"
          :class="`dialogSurvey__box user resp mb-6 ${
            client.clientOwnerCode === clientSelected.clientOwnerCode
              ? 'selected'
              : ''
          }`"
          @click="setClient(client)"
        >
          <h6>{{ client.firstName }} {{ client.lastName }}</h6>
        </div>
      </div>
    </v-dialog>
    <v-dialog
      fullscreen
      persistent
      v-model="dialogFirstVisit"
      :content-class="`dialogModalProfile step-${currentIndex}`"
    >
      <div
        class="dialogFirstVisit"
        :style="{ top: distanceY + 'px', left: distanceX + 'px' }"
      >
        <div class="dialogFirstVisit__header">
          <img
            class="close"
            src="../../assets/icons/ic-close-white.svg"
            @click="closeModal(pageChange)"
          />
        </div>
        <carousel
          v-if="filteredClientDetails.clientOwnerCode"
          :perPage="1"
          ref="carousel"
          :autoplay="false"
          paginationColor="#FFFFFF"
          paginationActiveColor="#97D700"
          :paginationPadding="5"
          easing="ease-in-out"
          v-on:pageChange="pageChange"
          :navigation-enabled="true"
          :navigation-next-label="nextBtn"
          :navigation-prev-label="prevBtn"
        >
          <slide v-for="(section, i) in sections" :key="i">
            <div class="dialogFirstVisit__body">
              <h3 class="HeadingsSubtitleM">{{ section.title }}</h3>
              <h4 class="BodyM">{{ section.body }}</h4>
            </div>
          </slide>
        </carousel>
        <div class="dialogFirstVisit__body" v-else>
          <h3 class="HeadingsSubtitleM">{{ sections[0].title }}</h3>
          <h4 class="BodyM">{{ sections[0].body }}</h4>
        </div>
        <v-btn
          v-if="currentIndex === 4"
          color="primary"
          rounded
          class="startSurvey"
          @click="openSurveyHouse"
        >
          <div class="ButtonS" v-if="filteredClientDetails.clientOwnerCode">
            Inizia questionario
          </div>
        </v-btn>
      </div>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="2000">
      <div class="changed BodyL-Strong">Profilo cambiato con successo</div>
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'
import { eventBus } from '@/main'
import router from '../../router/router'
import MYSButton from '../../components/global/MYSButton.vue'
import { fetchRemoteConfig } from '../../js/service/firebaseService'

export default {
  name: 'profileComponent',
  components: {
    Carousel,
    Slide,
    MYSButton
  },
  data() {
    return {
      snackbar: false,
      pageSelected: '',
      firstVisit: false,
      dialogUser: false,
      clientSelected: '',
      dialogFirstVisit: false,
      currentIndex: 0,
      distanceX: '',
      distanceY: '',
      sections: [
        {
          id: 1,
          title: 'Il mio profilo',
          body: 'Il profilo raccoglie informazioni personali, dati e documenti legali legati alle tue utenze.'
        },
        {
          id: 2,
          title: 'Le mie abitudini di consumo',
          body: 'Completa i questionari sulle tue abitudini di consumo. Ci permetterai di comprendere le tue esigenze e di proporti soluzioni adatte a te.'
        },
        {
          id: 3,
          title: 'Casa',
          tab: 'house',
          body: 'Le informazioni sul tipo di abitazione, sulla proprietà e sul numero di abitanti ci aiutano ad assisterti nella gestione dei consumi e nel presentarti le opportunità più adatte a te.'
        },
        {
          id: 4,
          title: 'Fotovoltaico',
          tab: 'photovoltaic',
          body: 'Puoi produrre la tua energia sfruttando la luce solare: scopri la configurazione di pannelli ideale per te.'
        },
        {
          id: 5,
          title: 'Auto',
          tab: 'mobility',
          body: 'Le informazioni sulla tua auto, anche se non è elettrica, ci permettono di comprendere le tue esigenze sulla mobilità, e di informarti sulle opportunità più utili.'
        }
      ]
    }
  },
  watch: {
    '$route.name': {
      handler: function (name) {
        this.pageSelected = name
      },
      deep: true,
      immediate: true
    }
  },
  beforeMount() {
    this.trackTapEvent({
      name: 'profile2_access_tap',
      params: { first_access: this.firstVisit }
    })
    if (!this.clientDetails) {
      this.getMainClient().then((resp) => {
        this.clientSelected = resp
        this.setUserProfile(this.clientSelected)
        this.isFirstVisitForUser(this.clientSelected)
      })
    }
  },
  mounted() {
    this.getPosition()
    this.trackTapEvent({
      name: 'profile_viewed'
    })
  },
  computed: {
    ...mapGetters('account', [
      'mainClient',
      'accountClients',
      'filteredClientDetails'
    ]),

    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'account' }, text: 'Profilo' }
      ]

      return res
    },
    nextBtn() {
      return `<v-btn
        color='primary'
        rounded
        class='ButtonS'
      >
        Avanti
      </v-btn>`
    },
    prevBtn() {
      return `<v-btn
        color='primary'
        rounded
        class='ButtonS'
      >
        Indietro
      </v-btn>`
    }
  },
  methods: {
    ...mapActions('account', ['getMainClient']),
    ...mapActions('session', ['logout']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('profile', ['setUserProfile', 'setTypOnboardingHouse']),
    logoutUser() {
      this.trackTapEvent('account_logout_tap')
      this.logout()
    },
    selectProfile() {
      if (Object.keys(this.accountClients).length > 1) this.dialogUser = true
    },
    getPosition() {
      // I calculate the position of the modal for the various steps in multiple screen sizes, not at resize.
      // I take as references the static html points to which to hang the modal
      let element = ''
      let width = ''
      let height = ''
      let rect = ''

      switch (this.currentIndex) {
        case 0: {
          element = this.$el.querySelector('.profile__sidemenu')
          rect = element.getBoundingClientRect()
          width = element.offsetWidth
          this.distanceX = rect.x + (width + 30)
          this.distanceY = rect.y + (rect.y / 2 - 131)
          break
        }
        case 1: {
          element = this.$el.querySelector('#tabProfile')
          rect = element.getBoundingClientRect()
          width = element.offsetWidth
          height = element.offsetHeight
          this.distanceX = rect.x + width / 2 - 177
          this.distanceY = rect.y + height + 50
          break
        }
        case 2:
        case 3:
        case 4: {
          element = this.$el.querySelector('#house')
          rect = element.getBoundingClientRect()
          width = 353
          this.distanceX = rect.x - width - 30
          this.distanceY = rect.y + 200
          break
        }
        default:
      }
    },
    setClient(client) {
      this.clientSelected = client
      this.setUserProfile(client)
      this.snackbar = true
      this.dialogUser = false
    },
    isFirstVisitForUser(client) {
      let onboardingStorage = JSON.parse(
        localStorage.getItem('onboardingProfile')
      )
      if (
        !onboardingStorage ||
        !onboardingStorage[this.mainClient.clientOwnerCode]
      ) {
        this.dialogFirstVisit = true
        this.firstVisit = true
        this.trackTapEvent('profile2_onboarding_view')
      } else {
        this.firstVisit = false
      }
    },
    closeModal() {
      this.firstVisit = false
      this.dialogFirstVisit = false
      let onboarding =
        JSON.parse(localStorage.getItem('onboardingProfile')) || {}
      onboarding[this.mainClient.clientOwnerCode] = true
      localStorage.setItem('onboardingProfile', JSON.stringify(onboarding))
      eventBus.$emit('prifile:onboarding')
      eventBus.$emit('profile:onboardingClosed')
    },
    pageChange(i) {
      // vue-carousel method that allows me to intercept the slide change
      this.currentIndex = i
      this.getPosition()
      if (i >= 2) {
        eventBus.$emit('onboarding:profile', this.sections[i].tab)
      }
      switch (i) {
        case 1:
          this.trackTapEvent('profile2_onboarding2_tap')
          break
        case 2:
          this.trackTapEvent('profile2_onboarding3_tap')
          break
        case 3:
          this.trackTapEvent('profile2_onboarding4_tap')
          break
        case 4:
          this.trackTapEvent('profile2_onboarding5_tap')
          break
        default:
          break
      }
    },
    openSurveyHouse() {
      this.closeModal()
      this.setTypOnboardingHouse(true)
      eventBus.$emit('house:changeSurvey')
    }
  }
}
</script>
<style lang="scss" src="./_onboarding.scss" />
<style lang="scss" src="./_dialogOnboarding.scss" />
<style lang="scss" scoped>
.std-side-padding-profile {
  margin-left: calc((100% - 1140px) / 2);
  margin-right: calc((100% - 1140px) / 2);
}
.profile {
  &.firstVisit {
    .v-snack {
      margin-left: 0;
    }
  }
  .firstVisitOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.48);
    opacity: 0.6;
    z-index: 202;
    top: 0px;
  }

  &__header {
    padding-top: 48px;
    padding-bottom: 48px;
    background: $srg-blue;
    .close {
      cursor: pointer;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: white;
  }

  &__content {
    display: flex;
    margin-top: 64px;
  }

  &__sidemenu {
    padding: 24px;
    width: 320px;
    height: min-content;
    background: #f8f9fc;
    border-radius: 8px;
    margin-right: 30px;
    flex-shrink: 0;

    h5 {
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.8px;
      color: $srg-blue;
      cursor: pointer;
    }
    img {
      cursor: pointer;
    }
    .menuName {
      font-weight: 700;
      font-size: 18px;
      line-height: 25.2px;
      letter-spacing: 0.8px;
    }
    .menuItems {
      color: #12256a;
      margin-bottom: 32px;
      width: fit-content;
      text-transform: uppercase;
      cursor: pointer;
      &.underline {
        color: #12256a;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 3px;
          background: #12256a;
          bottom: -4px;
          left: 0;
        }
      }
      &.underlineGrey {
        &:hover {
          color: $secondari-blue-100;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            background: $secondari-blue-100;
            bottom: -4px;
            left: 0;
          }
        }
      }
    }

    h6 {
      font-weight: 700;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      color: $srg-blue;
      margin-bottom: 26px;
      width: fit-content;
      cursor: pointer;
    }
  }
}

.dialogSurvey {
  padding: 40px 40px 48px;
  background: white;

  h3 {
    font-weight: 500;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: 1px;
    color: #000000;
  }

  .close {
    cursor: pointer;
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    width: 475px;
    height: 57px;
    background: #ffffff;
    border: 1px solid #dfe4ef;
    border-radius: 20px;
    cursor: pointer;

    &.resp {
      height: 50px;
      border-radius: 16px;

      &.selected {
        background: #eaeef5;
        &::after {
          content: url('../../assets/icons/ic-check-medium.svg');
          margin-right: 11px;
        }
      }

      h6 {
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: 0.8px;
        color: #12256a;
      }
    }
  }
}
</style>
<style lang="scss">
.profile {
  .v-snack {
    position: absolute;
    right: 100%;
    padding-left: 48px;
    bottom: 30px;
    justify-content: flex-start;
    .v-snack__wrapper {
      width: 300px;
      height: 56px;
      box-shadow: 0px 2px 24px rgba(44, 53, 66, 0.06),
        0px 1px 8px rgba(44, 53, 66, 0.08), 0px 2px 4px rgba(44, 53, 66, 0.08);
      border-radius: 16px;
      background-color: white;
      .changed {
        display: flex;
        align-items: center;
        color: black;
        &::before {
          content: url('../../assets/profile/check_ok.svg');
          margin-right: 8px;
          margin-top: 6px;
        }
      }
    }
  }
}
</style>
