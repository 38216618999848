<template>
  <div class="supply-detail topRadius mt-12">
    <TcVars />
    <v-row class="std-side-padding">
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row class="std-side-padding">
      <v-col>
        <v-row no-gutters class="bill-bidgely__row">
          <v-col class="col-10">
            <div class="bill-bidgely__title">I tuoi consumi</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="loaded" class="d-flex justify-center pa-10">
      <v-progress-circular :size="50" indeterminate></v-progress-circular>
    </v-row>
    <v-row class="std-side-padding">
      <iframe
        id="bidgely"
        frameborder="0"
        v-show="!loaded"
        width="100%"
        :height="heightIframe"
      ></iframe>
    </v-row>
  </div>
</template>
<script>
import GenErr from '@/js/genericErrors'
import { mapActions, mapGetters } from 'vuex'
import { ENV_CONFIGS } from '@/js/configs.js'
import { capitalize } from '@/js/utils'
import CheckBidgelyVisibility from '@/js/service/checkBidgelyVisibility'
import TcVars from '@/components/tcTracker/TcVars'

export default {
  name: 'SupplyBidgely',
  data() {
    return {
      detail: {},
      token: '',
      heightIframe: '1459',
      loaded: true
    }
  },
  components: {
    TcVars
  },
  async beforeCreate() {
    let client = this.$route.params.clientOwnerCode
    let supply = this.$route.params.supplyCode

    await this.$store.dispatch('supply/getSupplies')

    let clientDetail = await this.$store.dispatch(
      'account/getDetailForClient',
      { clientOwnerCode: client }
    )
    if (Object.keys(this.accountClients).length === 1) {
      clientDetail.mainRole = '1'
    }

    this.$store
      .dispatch('supply/getDetail', {
        clientOwnerCode: client,
        supplyCode: supply
      })
      .then((detail) => {
        const checkBidgelyVisibility = new CheckBidgelyVisibility(
          clientDetail,
          detail
        )
        if (
          !checkBidgelyVisibility.isBidgely(
            detail.activationDate,
            this.mainClient.mainRole
          )
        ) {
          this.$router.push({
            name: 'supplyDetail',
            params: { clientOwnerCode: client, supplyCode: supply }
          })
        }
        this.detail = detail
        this.$store
          .dispatch('supply/getTokenBidgely', {
            clientOwnerCode: client,
            pod: this.detail.pod
          })
          .then((resp) => {
            this.token = resp.token
            document.getElementById('bidgely').src =
              ENV_CONFIGS.BIDGELY_ENDPOINT
          })
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  mounted() {
    let self = this
    const handleMessage = (event) => {
      let data
      try {
        if (typeof event.data === 'string' && event.data.startsWith('TC.EX:')) {
          const jsonString = event.data.replace(/^TC\.EX:/, '')
          data = JSON.parse(jsonString)
        } else if (
          typeof event.data === 'string' &&
          event.data.trim().startsWith('{')
        ) {
          data = JSON.parse(event.data)
        } else {
          data = event.data // Non JSON, ma usa direttamente i dati
        }
      } catch (e) {
        console.error('Invalid JSON received:', event.data)
        return
      }
      if (data.type === 'onLoad' || data.type === 'webpackOk') {
        let iframe = document.getElementById('bidgely').contentWindow
        let json = JSON.stringify({ type: 'jwt', data: self.token })
        iframe.postMessage(json, '*')
      } else if (data.type === 'jwtLoaded') {
        this.loaded = false
      } else if (data.type === 'redraw' || data.height) {
        this.heightIframe = data.height + 40
      } else if (data.type === 'error') {
        GenErr.showErrorMessage(event.data.message)
      }
    }
    window.addEventListener('message', handleMessage)
  },
  beforeRouteLeave(to, from, next) {
    let iframe = document.getElementById('bidgely').contentWindow
    iframe.postMessage(
      {
        type: 'unload'
      },
      '*'
    )
    next()
  },
  methods: {
    ...mapActions('supply', ['getTokenBidgely'])
  },
  computed: {
    ...mapGetters('account', ['accountClients', 'mainClient']),
    breadcrumbsItems() {
      let res = []
      if (this.detail && this.detail.address) {
        let address = this.detail ? this.detail.address : ''
        address = capitalize(address.toLowerCase())
        res = [
          { to: { name: 'home' }, text: 'Home' },
          { to: { name: 'ownerSupplies' }, text: 'Utenze', exact: true },
          {
            to: {
              name: 'supplyDetail',
              params: {
                clientOwnerCode: this.$route.params.clientOwnerCode,
                supplyCode: this.$route.params.supplyCode
              }
            },
            text: address,
            exact: true
          },
          { to: { name: 'supplyBidgely' }, text: 'Beyond Energy' }
        ]
      }
      return res
    }
  }
}
</script>
<style lang="scss" scoped>
.bill-bidgely {
  &__navbar {
    display: flex;
    align-content: center;
    margin-bottom: 40px;
    h5 {
      color: black;
      &.capitalize {
        text-transform: capitalize;
      }
      &.bold {
        font-weight: 500;
      }
    }
    img {
      margin: 0 10px;
    }
  }
  &__title {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &__row {
    margin-bottom: 40px;
  }
}
</style>
