import { render, staticRenderFns } from "./SupplyBidgely.vue?vue&type=template&id=e62d57fa&scoped=true"
import script from "./SupplyBidgely.vue?vue&type=script&lang=js"
export * from "./SupplyBidgely.vue?vue&type=script&lang=js"
import style0 from "./SupplyBidgely.vue?vue&type=style&index=0&id=e62d57fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e62d57fa",
  null
  
)

export default component.exports