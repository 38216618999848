<template>
  <div>
    <template v-if="view === 'list' && this.canSaveAddress">
      <div class="d-flex justify-space-between align-center mb-6">
        <h3>La mia casa</h3>
        <img
          class="close"
          src="../../../assets/icons/ic-close.svg"
          @click="close"
        />
      </div>
      <h5 class="mb-6">
        Scegli l'indirizzo della tua casa o inseriscine uno nuovo
      </h5>
      <div
        :class="`dialogSurvey__box address mb-4 ${
          selected === address.egonIndirizzo ? 'selected' : ''
        }`"
        v-for="(address, i) in addressSaved"
        :key="i"
        @click="setAddress(address)"
      >
        <h5>{{ address.indirizzo }}</h5>
      </div>
      <div class="d-flex align-center justify-center mt-8">
        <h5 @click="changeView" class="addAddress">Aggiungi nuovo indirizzo</h5>
      </div>
    </template>
    <template v-else>
      <div class="d-flex justify-space-between align-center mb-12">
        <h3
          class="back"
          @click="
            $emit('update:view', 'list')
            $emit('setStartIndex')
          "
        >
          Aggiungi nuovo indirizzo
        </h3>
        <img
          class="close"
          src="../../../assets/icons/ic-close.svg"
          @click="close"
        />
      </div>
      <vuetify-google-autocomplete
        ref="address"
        id="map"
        classname="auth__input"
        placeholder="Inserisci il tuo indirizzo"
        v-on:placechanged="getAddressData"
        country="it"
        label="nuovo indirizzo"
        v-model.trim="newAddress"
        :value="addressObject"
        :error-messages="addressErrors"
        @blur="v$.newAddress.$touch()"
      >
        <template #append>
          <img src="@/assets/profile/search.svg" />
        </template>
      </vuetify-google-autocomplete>
      <div class="d-flex justify-center">
        <MYSButton
          :disabled="!newAddress || addressErrors.length > 0"
          :buttonText="this.canSaveAddress ? 'Aggiungi' : 'Aggiungi e prosegui'"
          :buttonClass="
            this.canSaveAddress
              ? 'primaryButton primaryButtonXS'
              : 'primaryButton primaryButtonL'
          "
          @buttonClick="
            getEgonAndSave()
            $emit('update:dialog', false)
          "
        />
      </div>
    </template>
  </div>
</template>
<script>
import { ENV_CONFIGS } from '@/js/configs'
import { capitalize } from '@/js/utils'
import { required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  AlertService,
  AlertServiceGasSardegna
} from '../../../js/service/alertService'

import { useVuelidate } from '@vuelidate/core'
import MYSButton from '../../../components/global/MYSButton.vue'

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'addressConsumption',
  props: ['address', 'dialog', 'view', 'canSaveAddress', 'typeCommodity'],
  data() {
    return {
      selected: '',
      newAddress: '',
      addressObject: {}
    }
  },
  components: {
    MYSButton
  },
  watch: {
    view(val) {
      if (val === 'list') {
        this.trackTapEvent({
          name: 'profile2_select_address_view',
          params: { client_code: this.userProfile.clientOwnerCode }
        })
      } else {
        this.trackTapEvent({
          name: 'profile2_add_address_view',
          params: { client_code: this.userProfile.clientOwnerCode }
        })
      }
    }
  },
  mounted() {
    if (this.view === 'list') {
      this.trackTapEvent({
        name: 'profile2_select_address_view',
        params: { client_code: this.userProfile.clientOwnerCode }
      })
    } else {
      this.trackTapEvent({
        name: 'profile2_add_address_view',
        params: { client_code: this.userProfile.clientOwnerCode }
      })
    }
  },
  computed: {
    ...mapGetters('profile', ['addressSaved', 'userProfile']),
    ...mapGetters('upsellingComm', [
      'client',
      'getFlagAddedAddress',
      'getFlagNewUpselling'
    ]),
    ...mapGetters('account', ['mainClient']),
    ...mapGetters('session', ['username']),

    googlePlacesKey() {
      return ENV_CONFIGS.GOOGLE_PLACES_KEY
    },
    addressErrors() {
      let errArray = []
      if (!this.v$.newAddress.$dirty) return errArray
      this.v$.newAddress.required.$invalid === true &&
        errArray.push("L'indirizzo è obbligatorio")
      typeof this.addressObject === 'string' &&
        errArray.push('Seleziona un indirizzo valido')
      !this.addressObject.street_number &&
        errArray.push('Il numero civico non è presente')
      !this.addressObject.route && errArray.push('La via non è presente')
      !this.addressObject.postal_code && errArray.push('Il CAP non è presente')
      !this.addressObject.locality && errArray.push('La città non è presente')
      return errArray
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('profile', ['saveAddress']),
    ...mapActions('account', ['getClientsForAccount', 'getDetailForClient']),
    ...mapMutations('profile', ['setTypOnboardingHouse', 'setTempAddress']),
    ...mapMutations('upsellingComm', [
      'updateAddress',
      'setClient',
      'setFlagAddedAddress',
      'setSupplyUpselling'
    ]),
    ...mapActions('egon', [
      'getEgonIndirizzo',
      'getEgonComune',
      'getComune',
      'getEgonVia',
      'getEgonCivico'
    ]),

    setAddress(address) {
      this.selected = address.egonIndirizzo
      this.$emit('update:address', address)
      this.$emit('next')
    },
    changeView() {
      this.$emit('update:view', 'addAddress')
    },
    close() {
      this.setTypOnboardingHouse(false)
      this.$emit('update:dialog', false)
      this.$emit('setStartIndex')
      this.$emit('resetFlagNewAddress')
    },
    getAddressData(addressData) {
      this.addressObject = addressData
      this.newAddress = `${addressData.route || ''} ${
        addressData.street_number || ''
      }, ${addressData.postal_code || ''}, ${addressData.locality || ''}`
      this.$forceUpdate()
    },
    async getEgonAndSave() {
      if (
        this.$route.name === 'gasOffering' &&
        this.addressObject.administrative_area_level_1 === 'Sardegna'
      ) {
        AlertServiceGasSardegna(
          'Indirizzo non raggiunto',
          "Ci dispiace, l'indirizzo selezionato non è raggiunto dalla rete nazionale",
          false,
          'Chiudi',
          '#'
        )
      } else {
        this.trackTapEvent({
          name: 'profile2_addAddressConfirm_tap',
          params: { client_code: this.userProfile.clientOwnerCode }
        })
        let body = {
          civico: this.addressObject.street_number,
          via: this.addressObject.route,
          cap: this.addressObject.postal_code,
          comune: this.addressObject.locality
        }
        let codComune = await this.getComune(body.comune.toUpperCase())
        this.getEgonIndirizzo(body)
          .then(async (res) => {
            let address = {
              egonIndirizzo: res.idEgonCivico,
              cap: res.cap,
              comune: res.comune,
              provincia: res.provincia,
              indirizzo: `${capitalize(res.via)} ${res.civico}, ${capitalize(
                res.comune
              )}`
            }
            this.$emit('update:address', address)
            this.$emit('resetState')
            if (this.canSaveAddress) {
              this.saveAddress(address).then(() => {
                this.$emit('next')
              })
            } else {
              this.setFlagAddedAddress(true)
              this.updateAddress({
                clientOwnerCode: this.client.clientOwnerCode,
                address: `${capitalize(res.via)},${res.civico}, ${capitalize(
                  res.comune
                )}`,
                egonLocality: '',
                egonRegion: '',
                egonStreet: res.idEgonVia,
                egonStreetNumber: res.idEgonCivico,
                streetNumber: res.civico,
                egonStreetNumberLetter: res.idEgonCivico,
                comune: res.comune,
                codComune: codComune[0].codComune,
                comuneEgon: res.idEgonComune,
                provincia: res.provincia,
                zip: res.cap
              })
              let body = {
                clientOwnerCode: this.client.clientOwnerCode,
                addressComplete: `${capitalize(res.via)}, ${
                  res.civico
                }, ${capitalize(res.comune)}`,
                egonLocality: '',
                egonRegion: '',
                egonStreet: res.idEgonVia,
                egonStreetNumber: res.idEgonCivico,
                streetNumber: res.civico,
                egonStreetNumberLetter: res.idEgonCivico,
                city: res.comune,
                county: res.provincia,
                zipCode: res.cap
              }
              body = { ...body, ...this.client }

              this.setSupplyUpselling(body)
              this.$emit('setFlowType', 'selectedAddress')

              if (this.getFlagNewUpselling && this.typeCommodity === 'gas') {
                this.$emit('next')
              } else {
                this.$router.push({ name: `offers${this.typeCommodity}` })
              }
              this.$emit('setStartIndex')
            }
          })
          .catch(() => {
            AlertService(
              'Servizio non disponibile',
              "Al momento non è possibile salvare l'indirizzo. Ti invitiamo a riprovare.",
              false,
              'Chiudi',
              '#'
            )
          })
      }
    }
  },
  validations: {
    newAddress: { required }
  }
}
</script>
<style lang="scss" scoped>
@import './styleSurvey.scss';
</style>
