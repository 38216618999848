import { axiosACN, axiosCMS } from '@/js/axiosInstances.js'
import GenErr from '@/js/genericErrors'
import { get } from 'lodash'

const retrieveOffersList = (commit, rootGetters, params) => {
  return axiosACN
    .post(
      '/fibra/retrieveListinoFibraClienteProspect',
      {
        tipoFunnel: `CLIENTE ${rootGetters['upsellingFiber/addressCovered'].clientOwnerType}`,
        tipoContratto: params.type,
        banda: params.speed || undefined
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      if (res.data.error) {
        throw Error('UNKNOWN_ERROR')
      }
      const tipoContratto = params.type === 'APP_MONO'
      const offer = {
        idFiberList: res.data.fibra.idListinoFibra,
        endDate: res.data.fibra.dataValidita,
        price: res.data.fibra.prezzo,
        discount: tipoContratto
          ? res.data.fibra.scontoMono
          : res.data.fibra.scontoDual,
        discountPerc: res.data.fibra.scontoPerc,
        discountMonth: res.data.fibra.scontoMesi,
        listPrice: res.data.fibra.listinoFibra,
        document: tipoContratto
          ? res.data.fibra.nameDocAppMono
          : res.data.fibra.nameDocAppDual,
        isScalino: res.data.fibra.importoScontoScalino > 0,
        scontoScalino: res.data.fibra.importoScontoScalino || 0,
        durataScontoScalino: res.data.fibra.durataScontoScalino || 0,
        speedOffer: params.speed || ''
      }

      commit('setOffers', offer)

      return offer
    })
    .catch((err) => {
      if (err.statusCode && err.statusCode >= 400) {
        throw GenErr.showErrorMessage()
      } else if (err.message) {
        throw GenErr.showErrorMessage()
      }
    })
}

const retrieveDocumentFiber = (commit, rootGetters, state) => {
  return new Promise(function (resolve, reject) {
    axiosACN
      .get(
        `/commodity/V2/documento?name=${state.offers.document}&idListinoFibra=${state.offers.idFiberList}`,
        {
          responseType: 'blob',
          headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
        }
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' })
        const blobUrl = URL.createObjectURL(blob)
        commit('FETCH_DOC_FIBER', {
          doc: blobUrl
        })
        resolve(blobUrl)
      })
  })
}

// const retrieveDocumentComm = (commit, rootGetters, state, name) => {
//   const commodityType = rootGetters['upsellingComm/commodityType'].toUpperCase()
//   return new Promise(function (resolve, reject) {
//     axiosACN
//       .get(
//         `/commodity/V2/documento?name=${name}&tipoContratto=${commodityType}`,
//         {
//           responseType: 'arraybuffer',
//           headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
//         }
//       )
//       .then((res) => {
//         const blob = new Blob([res.data], { type: 'application/pdf' })
//         var blobUrl = URL.createObjectURL(blob)
//         resolve(blobUrl)
//       })
//   })
// }

const retrieveDocumentComm = async (commit, rootGetters, state, name) => {
  const commodityType = rootGetters['upsellingComm/commodityType'].toUpperCase()
  try {
    const response = await axiosACN.get('/commodity/V2/documento', {
      params: { name, tipoContratto: commodityType },
      responseType: 'arraybuffer',
      headers: { Authorization: `Bearer ${rootGetters['session/token']}` }
    })
    const blob = new Blob([response.data], { type: 'application/pdf' })
    const blobUrl = URL.createObjectURL(blob)
    return blobUrl
  } catch (error) {
    console.error('Errore durante il recupero del documento PDF:', error)
    throw error
  }
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    offers: null,
    documentFiber: null,
    upsellingInfo: null,
    upsellingInfoComm: null,
    paramsChangeProduct: {},
    changeProduct: null
  },
  getters: {
    offers: (state) => state.offers,
    upsellingInfo: (state) => state.upsellingInfo,
    upsellingInfoComm: (state) => {
      delete state.upsellingInfoComm['12'] // eliminare questa riga per visualizzare anche N12
      return state.upsellingInfoComm
    },
    documentFiber: (state) => state.documentFiber,
    changeProduct: (state) => state.changeProduct,
    getParamsChangeProduct: (state) => state.paramsChangeProduct
  },
  mutations: {
    setOffers(state, offers) {
      state.offers = offers
    },
    FETCH_DOC_FIBER(state, { doc }) {
      state.documentFiber = doc
    },
    setUpsellingInfo(state, payload) {
      state.upsellingInfo = payload
    },
    setUpsellingInfoComm(state, payload) {
      state.upsellingInfoComm = payload
    },
    setChangeProduct(state, changeProduct) {
      state.changeProduct = changeProduct
    },
    setParamsChangeProduct(state, params) {
      state.paramsChangeProduct = params
    },
    resetState(state) {
      state.offers = null
      state.upsellingInfo = null
      state.upsellingInfoComm = null
      state.paramsChangeProduct = {}
    }
  },
  actions: {
    getOffers({ state, commit, rootGetters }, params) {
      return retrieveOffersList(commit, rootGetters, params)
    },
    getDocumentFiber({ state, commit, rootGetters }) {
      return retrieveDocumentFiber(commit, rootGetters, state)
    },
    getDocumentCommodity({ state, commit, rootGetters }, params) {
      return retrieveDocumentComm(commit, rootGetters, state, params)
    },
    getUpselling({ dispatch, commit, rootGetters }, body) {
      const params = { ...body, client_commodities: 'all' }
      return axiosCMS
        .get('/upselling-offert-details', { params })
        .then(async (resp) => {
          if (body.utility === 'fibra') {
            let tipologia = ''
            tipologia = rootGetters['upsellingFiber/addressCovered'].typology
            let offer = null
            if (body.current_client_type === 'BUS') {
              offer = resp.data.filter((r) => r.id === 'next-fibra-business')[0]
            } else if (tipologia) {
              tipologia =
                tipologia === 'FTTH_GPON'
                  ? 'Fastweb-FTTH_FW_GPON'
                  : 'Fastweb-' + tipologia
              offer = resp.data.filter((r) => r.id === tipologia)[0]
            } else {
              if (body.current_client_type === 'RES') {
                offer = resp.data.filter((r) => r.id === 'next-fibra')[0]
              } else {
                offer = resp.data.filter(
                  (r) => r.id === 'next-fibra-business'
                )[0]
              }
            }
            // const offerDetail = await dispatch('getUpsellingDetailById', offer.id)
            // offer.details = offerDetail[0].details || []
            // offer.header_title = offerDetail[0].header_title || ''
            commit('setUpsellingInfo', offer)
          } else {
            commit('setUpsellingInfo', resp.data[0])
            let info = {}
            await Promise.all(
              resp.data.map(async (offer) => {
                if (offer.id.includes('24')) {
                  // const offerDetail = await dispatch('getUpsellingDetailById', offer.id)
                  // offer.header_title = offerDetail[0].header_title || ''
                  offer.min_saving_limit =
                    offer.min_saving_limit.length > 0
                      ? offer.min_saving_limit
                      : '0'
                  info[24] = offer
                }
                if (offer.id.includes('12')) {
                  // const offerDetail = await dispatch('getUpsellingDetailById', offer.id)
                  // offer.header_title = offerDetail[0].header_title || ''
                  offer.min_saving_limit =
                    offer.min_saving_limit.length > 0
                      ? offer.min_saving_limit
                      : '0'
                  info[12] = offer
                }
              })
            )
            commit('setUpsellingInfoComm', info)
          }
        })
        .catch((err) => {
          alert(err)
        })
    },
    // getUpsellingDetailById ({}, id) {
    //     return axiosCMS.get(`/upselling-offert-details/${id}`).then((resp) => { return resp.data }).catch((err) => { alert(err) })
    // },
    resetUpsellingInfo({ state }) {
      state.upsellingInfo = null
    },
    getChangeProduct({ commit, rootGetters }, params) {
      return axiosACN
        .post(
          '/fornitura/V3/retrieveChangeProduct',
          {
            clientCode: params.clientCode,
            prCode: params.prCode,
            productCode: params.productCode,
            tariffList: params.tariffList
          },
          {
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )
        .then((res) => {
          if (res.data.errorCode === '500') {
            throw Error('SERVER_ERROR')
          }
          if (res.data.errorCode === '1117') {
            return 'EXPIRED'
          } else if (res.data.errorCode === '1116') {
            return 'OFFER_NOT_FOUND'
          } else {
            commit('setChangeProduct', res.data.result)
            return res.data.result
          }
        })
        .catch((err) => {
          throw err.response.data
        })
    },
    submitChangeProduct({ rootGetters }, body) {
      return axiosACN
        .post('/fornitura/changeProduct', body, {
          headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
        })
        .then((res) => {
          if (res.data.status === 'KO') {
            if (res.data.errorCode === '001') {
              return 'alreadySigned'
            }
            GenErr.showErrorMessage()
            throw Error('UNKNOWN_ERROR')
          }
          if (res.data.errorCode) {
            // No bus logic errors expected
            throw Error('UNKNOWN_ERROR')
          }
          return res.data.status
        })
        .catch((err) => {
          GenErr.showErrorMessage()
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    }
  }
}
