import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeNew from '../views/newHome/Home.vue'
import OwnerSupplies from '../views/supplies/OwnerSupplies.vue'

import Login from '../views/Login.vue'
import ChangePwd from '../views/ChangePwd.vue'
import RetrieveCredentials from '../views/RetrieveCredentials.vue'
import RetrievePassword from '../views/RetrievePassword.vue'
import Notice from '../views/notice/Notice.vue'
import Redirect from '../views/redirect/Redirect.vue'

import Tutorial from '../views/tutorial/Tutorial'
import WelcomeKit from '../views/welcome/WelcomeKit'
import CleanEnergy from '../views/welcome/CleanEnergy'
import CleanEnergyNew from '../views/welcome/CleanEnergyNew'
import BillSorgenia from '../views/welcome/BillSorgenia'
import StandardWelcomeKit from '../views/welcome/StandardWelcomeKit.vue'

import SelfRegistration from '../views/selfRegistration/SelfRegistration.vue'
import RegistrationUserData from '../views/selfRegistration/RegistrationUserData.vue'
import RegistrationPrivacy from '../views/selfRegistration/RegistrationPrivacy.vue'
import RegistrationConfirmation from '../views/selfRegistration/RegistrationConfirmation.vue'
import GasOffering from '../views/gas&light/Gus'
import EleOffering from '../views/gas&light/Electricity'

import Supply from '../views/supply/Supply.vue'
import SupplyDetail from '../views/supply/SupplyDetail.vue'
import Template from './Template'
import BillDetail from '../views/bills/BillDetail.vue'
import PdrDetail from '../views/pdr/PdrDetail.vue'
import billsArchive from '../views/bills/archive/BillsArchive.vue'
import supplyPaymentResult from '../views/supply/SupplyPaymentResult.vue'
import supplyBidgely from '../views/supply/SupplyBidgely.vue'

import Account from '../views/account/Account.vue'
import Profile from '../views/profile/Profile.vue'
import ConsumptionProfile from '../views/profile/ConsumptionProfile.vue'
import InformationProfile from '../views/profile/InformationProfile.vue'
import DocumentsProfile from '../views/profile/DocumentsProfile.vue'
import InformativeProfile from '../views/profile/InformativeProfile.vue'
import Afterthought from '../views/account/Afterthought.vue'

import PrivateArea from '../components/PrivateArea.vue'

import SupportTemplate from '../views/support/SupportTemplate'
import Support from './support'

// import Fiber from '../views/fiber/Fiber.vue'
// import FiberOffering from '../views/fiber/offering'
// import FiberPromoRouter from '../views/fiber/FiberPromoRouter.vue'
// import FiberSubscription from '../views/fiber/FiberSubscription.vue'
// import FiberSummary from '../views/fiber/FiberSummary.vue'
// import FiberSubcriptionResult from '../views/fiber/FiberSubcriptionResult.vue'
// import FiberMigration from '../views/fiber/FiberMigration.vue'
import FiberRequestSent from '../views/fiber/FiberRequestSent.vue'

import SelectAddressResBus from '../views/newFiber/SelectAddressResBus.vue'
import SpeedChoice from '../views/newFiber/SpeedChoice.vue'
import FiberFlow from '../views/newFiber/FiberFlow.vue'
import FiberFlowBus from '../views/newFiber/FiberFlowBus.vue'
import EleFlow from '../views/gas&light/newUpselling/EleFlow.vue'
import GasFlow from '../views/gas&light/newUpselling/GasFlow.vue'
import CommodityOffers from '../views/gas&light/newUpselling/CommodityOffers.vue'
import SuccessUpload from '../views/newFiber/thankyouPage/SuccessUpload.vue'
import SuccessSubscribedd from '../views/newFiber/thankyouPage/SuccessSubscribed.vue'
import fiberNewRequestSent from '../views/newFiber/thankyouPage/RequestSent.vue'
import fiberNewRequestBusSent from '../views/newFiber/thankyouPage/RequestBusSent.vue'
import RequestSentCommodity from '../views/gas&light/newUpselling/thankyouPage/RequestSent.vue'
import SuccessUploadCommodity from '../views/gas&light/newUpselling/thankyouPage/SuccessUpload.vue'
import SuccessSubscribeddComm from '../views/gas&light/newUpselling/thankyouPage/SuccessSubscribed.vue'

import ChangeOfferIndex from '../views/gas&light/changeOffer/ChangeOfferIndex.vue'
import ChangeOfferCommodity from '../views/gas&light/changeOffer/ChangeOffer.vue'
import ResultChangeOffer from '../views/gas&light/changeOffer/ResultPage.vue'

import TrackingFiber from '../views/tracking/TrackingFiber.vue'
import TrackingCommodity from '../views/tracking/TrackingCommodity.vue'
import TrackingPhotovoltaic from '../views/tracking/TrackingPhotovoltaic.vue'

import store from '../store'
import NewTracker from '../views/gas&light/newTracker/NewTracker'
import SuccessPage from '../views/gas&light/SuccessPage'

import SelfReadingLight from '../views/actions/light/selfReading/selfReading.vue'
import SelfReadingLightBase from '../views/actions/light/selfReading/base.vue'
import SelfReadingLightBase2 from '../views/actions/light/selfReading/base2.vue'
import SelfReadingLightWarning1 from '../views/actions/light/selfReading/warning1.vue'
import SelfReadingLightWarning2 from '../views/actions/light/selfReading/warning2.vue'
import SelfReadingLightContactme from '../views/actions/light/selfReading/contactme.vue'
import SelfReadingLightThankyou from '../views/actions/light/selfReading/thankyou.vue'

import ParentalControl from '@/views/parentalControl/ParentalControl'
import FiberAction from '../views/fiber/FiberAction'
import Power from '../views/gas&light/lightActions/Power'
import Payment from '../views/gas&light/lightActions/Payment'
import Counter from '../views/gas&light/lightActions/Counter'
import Voltage from '../views/gas&light/lightActions/Voltage'
import NewPaymentMethod from '../views/gas&light/lightActions/NewPaymentMethod'
import CadastralUser from '../views/gas&light/lightActions/CadastralUser.vue'
import CadastralData from '../views/gas&light/lightActions/CadastralData.vue'
import CadastralNoData from '../views/gas&light/lightActions/CadastralNoData.vue'
import CadastralSuccess from '../views/gas&light/lightActions/CadastralSuccess.vue'
import PromotionSuccess from '../views/PromotionSuccess'

import Catalogue from '../views/loyalty/CataloguePage'
import LoyaltyPage from '../views/loyalty/LoyaltyPage'
import BonusDetail from '../views/loyalty/BonusDetailPage'
import Survey from '../views/loyalty/SurveyPage'
import Quiz from '../views/loyalty/Quiz'
import SuccessQuiz from '../views/loyalty/SuccessQuiz'
import SurveyRewardPage from '../views/loyalty/SurveyRewardPage'
import VideoPage from '../views/loyalty/video/'
import VideoFeedbackPage from '../views/loyalty/video/feedback/'
import VideoRewardPage from '../views/loyalty/video/reward/'
import UploadPhotoPage from '../views/loyalty/UploadPhotoPage'
import SuccessUploadPage from '../views/loyalty/SuccessUploadPage'
import BecomeGreenerPage from '../views/loyalty/landing/BecomeGreenerPage'
import JoinTeamPage from '../views/loyalty/landing/JoinTeamPage'
import ShippingFormPage from '../views/loyalty/landing/ShippingFormPage'
import ShipConfirmPage from '../views/loyalty/landing/ShipConfirmPage'
import GreenersFriendCode from '../views/loyalty/gifts/GreenersFriendCode'
import LandingPage from '../views/loyalty/landing/LandingPage'
import HowItWorksPage from '../views/loyalty/levels/HowItWorksPage'
import HowItWorksPage1 from '../views/loyalty/levels/HowItWorksPage1'
import CoinsPage from '../views/loyalty/levels/CoinsPage'
import RulesPage from '../views/loyalty/levels/RulesPage'
import RulesSuccessPage from '../views/loyalty/levels/SuccessPage'
import GiftsMainPage from '../views/loyalty/gifts/GiftsMainPage'
import IWGiftPage from '../views/loyalty/gifts/IWGiftPage'
import BicycleReward from '../views/loyalty/gifts/BicycleRewardPage'
import GiftShippingPage from '../views/loyalty/gifts/GiftShippingPage'
import WelcomeGiftPage from '../views/loyalty/gifts/WelcomeGiftPage'
import CodeResultPage from '../views/loyalty/gifts/CodeResultPage'
import GiftCodePage from '../views/loyalty/gifts/GiftCodePage'
import GiftDetailsPage from '../views/loyalty/gifts/GiftDetailsPage'
import GiftEmailShippingPage from '../views/loyalty/gifts/GiftEmailShippingPage'
import PromotionPage from '../views/loyalty/gifts/PromotionPage'
import Registered from '../views/loyalty/Registered'

import StandardTutorial from '../views/tutorial/StandardTutorial.vue'
import Feedback from '../views/feedback/Feedback'
import FeedbackSurvey from '../views/feedback/FeedbackSurvey'

import PaymentSummary from '../views/paymentSummary/PaymentSummary.vue'
import LandingPromo from '../views/home/LandingPromo.vue'

import DreamServiceList from '../views/support/DreamServiceList'
import DreamServiceDetail from '../views/support/DreamServiceDetail'

import MyShop from '../views/myShop/MyShop'

import PayBill from '../views/bills/PayBill.vue'
import RequestCredit from '../views/supply/RequestCredit.vue'

import PayMultipleBills from '../views/bills/PayMultipleBills.vue'
import ServiceProfile from '../views/serviceProfile/ServiceProfile/ServiceProfile.vue'
import EntryPage from '../views/serviceProfile/EntryPage/EntryPage'
import ServiceSection from '../views/serviceProfile/ServiceSection/ServiceSection.vue'
import RegistrationChoise from '../views/selfRegistration/RegistrationChoise.vue'
import LoginProspectQRCode from '../views/LoginProspectQRCode.vue'
import LoginAccountNotReady from '../views/LoginAccountNotReady.vue'

import DashboardFotovoltaico from '../views/fotovoltaico/dashboard/DashboardFotovoltaico.vue'
import FotovoltaicoProduzione from '../views/fotovoltaico/monitor/FotovoltaicoProduzione.vue'
import FotovoltaicoConsumo from '../views/fotovoltaico/monitor/FotovoltaicoConsumo.vue'
import FotovoltaicoRisparmio from '../views/fotovoltaico/monitor/FotovoltaicoRisparmio.vue'
import FotovoltaicoImpatto from '../views/fotovoltaico/monitor/FotovoltaicoImpatto.vue'

import ConfirmAccountDeleted from '../views/ConfirmAccountDeleted.vue'

import { ENV_CONFIGS } from '../js/configs'
import PunDetail from '../views/supply/PunDetail.vue'

Vue.use(VueRouter)
const checkAuthentication = (next, to) => {
  if (!store.getters['session/isAuthenticated']) {
    store.dispatch('session/tryAutoLogin')
  }

  if (store.getters['session/isAuthenticated']) {
    next()
  } else {
    const autoForwardURL = '?forwardURL=' + to.fullPath
    next('/login' + autoForwardURL)
  }
}

const checkEnableSuperLogin = (next, to) => {
  const checkEnable = ENV_CONFIGS.ENABLE_SUPERLOGIN_ROUTE === 'true'
  if (checkEnable) {
    next()
  } else {
    next('/login')
  }
}

const checkMultipleMainClients = (to, from, next) => {
  if (
    store.getters['account/accountProperties'].type === 'MIXED' &&
    store.getters['account/loyaltyResToFix'] &&
    store.getters['account/loyaltyBusToFix']
  ) {
    router.push({ name: from.name, query: { isMultiMainClients: true } })
  } else if (
    store.getters['account/accountProperties'].type === 'RES' &&
    store.getters['account/loyaltyResToFix']
  ) {
    router.push({ name: from.name, query: { isMultiMainClients: true } })
  } else if (
    store.getters['account/accountProperties'].type === 'BUS' &&
    store.getters['account/loyaltyBusToFix']
  ) {
    router.push({ name: from.name, query: { isMultiMainClients: true } })
  } else {
    next()
  }
}

const checkAlertError = (to, from, next) => {
  next()
  if (
    (store.getters['loyalty/IDENTITY_STATUS_ERROR'] ||
      store.getters['loyalty/IDENTITY_BALANCE_ERROR'] ||
      store.getters['loyalty/IDENTITY_LEVEL_ERROR']) &&
    (to.name === 'loyaltyPage' || to.name === 'introPage')
  ) {
    router.push({
      name: from.name,
      params: { typeError: 'GENERIC_ERROR_HOME' }
    })
  } else if (
    store.getters['loyalty/AWARD_CATALOG_ERROR'] &&
    to.name === 'catalogue'
  ) {
    router.push({
      name: from.name,
      params: { typeError: 'GENERIC_ERROR_CATALOG' }
    })
  } else if (
    (store.getters['loyalty/IDENTITY_BALANCE_ERROR'] ||
      store.getters['loyalty/TRANSACTION_LIST_ERROR']) &&
    to.name === 'coinsPage'
  ) {
    router.push({
      name: from.name,
      params: { typeError: 'GENERIC_ERROR_DIARY' }
    })
  } else if (
    store.getters['loyalty/REQUEST_AWARD_DIARY_ERRROR'] &&
    to.name === 'shippingConfirm'
  ) {
    router.push({
      name: from.name,
      params: { typeError: 'GENERIC_ERROR_AWARD_DIARY' }
    })
  } else if (
    store.getters['loyalty/REQUEST_AWARD_CATALOG_ERRROR'] &&
    to.name === 'shippingConfirm'
  ) {
    router.push({
      name: from.name,
      params: { typeError: 'GENERIC_ERROR_AWARD_CATALOG' }
    })
  } else if (
    store.getters['loyalty/REQUEST_AWARD_GREENCOIS_ERRROR'] &&
    to.name === 'shippingConfirm'
  ) {
    router.push({
      name: from.name,
      params: { typeError: 'GENERIC_ERROR_GREENCOINS' }
    })
  } else if (
    store.getters['loyalty/AWARD_REDEEM_ERRROR'] &&
    (to.name === 'giftsMainPage' ||
      to.name === 'iwGift' ||
      to.name === 'promote')
  ) {
    router.push({
      name: from.name,
      params: { typeError: 'GENERIC_ERROR_DIARY' }
    })
  } else if (
    (store.getters['loyalty/WEKITMAP_ERROR'] ||
      store.getters['loyalty/TRANSACTION_ERROR']) &&
    (to.name === 'surveyReward' ||
      to.name === 'videoRewardPage' ||
      to.name === 'successUpload' ||
      to.name === 'successQuiz')
  ) {
    router.push({
      name: from.name,
      params: { typeError: 'GENERIC_ERROR_MISSIONS' }
    })
  } else if (
    store.getters['loyalty/REQUEST_AWARD_CATALOG_ERRROR'] &&
    to.name === 'giftEmailShipping'
  ) {
    router.push({
      name: from.name,
      params: { typeError: 'GENERIC_ERROR_AWARD_CATALOG' }
    })
  } else if (
    store.getters['loyalty/REQUEST_AWARD_CATALOG_ERRROR'] &&
    to.name === 'giftShipping'
  ) {
    router.push({
      name: from.name,
      params: { typeError: 'GENERIC_ERROR_AWARD_CATALOG' }
    })
  } else {
    next()
  }
}

const setTitlePageWithoutHeader = (to, from, next, title) => {
  next()
  document.title = TITLE_PREFIX + title
}
const switchToBusOrResUpselling = (to, from, next) => {
  const accountProps = store.getters['account/accountProperties']
  if (!accountProps || !accountProps.detailedType) {
    next()
  } else if (accountProps.detailedType.includes('BUS')) {
    router.push({ name: to.name + 'BUS' })
  } else {
    next()
  }
}

const switchPageAccount = async (to, from, next) => {
  let isOnlyFv =
    store.getters['account/filteredClientDetails'] &&
    !store.getters['account/filteredClientDetails'].clientOwnerCode
  Promise.resolve(store.getters['account/accountProperties']).then(
    async (accountProps) => {
      if (!accountProps.detailedType) {
        await store.dispatch('supply/getSupplies')
      }
      if (accountProps.detailedType.includes('RES') && to.name === 'account') {
        if (!to.params.showContract && !isOnlyFv) {
          router.push({
            name: 'consumptionProfile'
          })
        } else if (!to.params.showContract && isOnlyFv) {
          router.push({
            name: 'informationProfile'
          })
        } else {
          router.push({
            name: 'documentsProfile'
          })
        }
      } else if (
        accountProps.detailedType.includes('BUS') &&
        to.fullPath.includes('profile')
      ) {
        router.push({ name: 'account' })
      } else {
        next()
      }
    }
  )
}
const switchPageService = async (to, from, next) => {
  Promise.resolve(store.getters['account/accountProperties']).then(
    (accountProps) => {
      if (accountProps.detailedType?.includes('RES')) {
        next()
      } else {
        router.push({ name: 'home' })
      }
    }
  )
}

const TITLE_PREFIX = ''
const TITLE_SUFFIX = ' - My Sorgenia'

const routes = [
  {
    path: '/checkup',
    name: 'checkup',
    beforeEnter: function (to, from, next) {
      setTitlePageWithoutHeader(to, from, next, 'Checkup Energetico')
    },
    component: () =>
      import(
        /* webpackChunkName: "checkup" */ '../views/checkup_energetico/CheckupType.vue'
      )
  },
  {
    path: '/prospectLogin',
    name: 'ProspectLogin',
    component: LoginProspectQRCode,
    meta: {
      title: TITLE_PREFIX + 'Prospect redirect' + TITLE_SUFFIX
    }
  },
  {
    path: '/loginAccountNotReady',
    name: 'AccountNotReady',
    component: LoginAccountNotReady,
    meta: {
      title: TITLE_PREFIX + 'Login Account not ready' + TITLE_SUFFIX
    }
  },
  {
    path: '/checkup/short',
    name: 'checkupShort',
    component: () =>
      import(
        /* webpackChunkName: "checkupshort" */ '../views/checkup_energetico/CheckupFlowShort.vue'
      )
  },
  {
    path: '/checkup/complete',
    name: 'checkupComplete',
    component: () =>
      import(
        /* webpackChunkName: "checkupcomplete" */ '../views/checkup_energetico/CheckupFlowComplete.vue'
      )
  },
  {
    path: '/checkup/completed',
    name: 'checkupCompleted',
    beforeEnter: function (to, from, next) {
      setTitlePageWithoutHeader(to, from, next, 'Checkup Energetico Completato')
    },
    component: () =>
      import(
        /* webpackChunkName: "checkupcompleted" */ '../views/checkup_energetico/completed/SendSurvey.vue'
      )
  },
  {
    path: '/checkup/result',
    name: 'checkupResult',
    component: () =>
      import(
        /* webpackChunkName: "checkupresult" */ '../views/checkup_energetico/completed/SurveyResult.vue'
      )
  },
  {
    path: '/checkup/result-sent',
    name: 'checkupSent',
    beforeEnter: function (to, from, next) {
      setTitlePageWithoutHeader(to, from, next, 'Checkup Energetico Terminato')
    },
    component: () =>
      import(
        /* webpackChunkName: "checkupSent" */ '../views/checkup_energetico/completed/SurveySent.vue'
      )
  },
  {
    path: '/exitCheckup',
    name: 'ExitCHECKUP'
  },
  {
    path: '/openClientMail',
    name: 'OpenClientMail'
  },
  {
    path: '/superlogin',
    name: 'superlogin',
    component: () =>
      import(
        /* webpackChunkName: "superlogin" */ '../views/SuperLogin/SuperLogin.vue'
      ),
    beforeEnter: function (to, from, next) {
      checkEnableSuperLogin(next, to)
    },

    meta: {
      title: TITLE_PREFIX + 'SuperLogin' + TITLE_SUFFIX
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: TITLE_PREFIX + 'Login' + TITLE_SUFFIX
    }
  },
  {
    path: '/confirmAccountDeleted',
    name: 'confirmAccountDeleted',
    component: ConfirmAccountDeleted,
    meta: {
      title: TITLE_PREFIX + 'Goodbye' + TITLE_SUFFIX
    }
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: ChangePwd,
    meta: {
      title: TITLE_PREFIX + 'Cambio password' + TITLE_SUFFIX
    }
  },
  {
    path: '/retrievecredentials',
    name: 'retrieveCredentials',
    component: RetrieveCredentials,
    meta: {
      title: TITLE_PREFIX + 'Recuper credenziali' + TITLE_SUFFIX
    }
  },
  {
    path: '/retrievepassword',
    name: 'retrievePassword',
    component: RetrievePassword,
    meta: {
      title: TITLE_PREFIX + 'Recupero password' + TITLE_SUFFIX
    }
  },
  {
    path: '/notice',
    name: 'notice',
    component: Notice,
    meta: {
      title: ''
    }
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: Redirect
  },
  {
    path: '/welcome-kit',
    name: 'publicWelcomekit',
    component: WelcomeKit
  },
  {
    path: '/welcome-kit/clean-energy',
    name: 'publicCleanEnergy',
    component: CleanEnergy
  },
  {
    path: '/welcome-kit/clean-energy-new',
    name: 'publicCleanEnergyNew',
    component: CleanEnergyNew
  },
  {
    path: '/welcome-kit/bill-sorgenia',
    name: 'publicBillSorgenia',
    component: BillSorgenia
  },
  {
    path: '/welcome-kit/standard/:id',
    name: 'publicStandardWelcomeKit',
    component: StandardWelcomeKit
  },
  {
    path: '/welcome-kit/done',
    name: 'publicWelcomekitDone'
  },
  {
    path: '/tutorial',
    name: 'publicTutorial',
    component: Tutorial
  },
  {
    path: '/tutorial/standard/:id',
    name: 'publicStandardTutorial',
    component: StandardTutorial
  },
  {
    path: '/feedback',
    name: 'publicFeedback',
    component: Feedback
  },
  {
    path: '/feedback/survey/:id',
    name: 'publicFeedback-survey',
    component: FeedbackSurvey
  },
  {
    path: '/support/statuses/:clientCode/dreamservice',
    name: 'publicDreamServiceList',
    component: DreamServiceList
  },
  {
    path: '/support/statuses/:clientCode/dreamservice/detail/:requestId',
    name: 'publicDreamServiceDetail',
    component: DreamServiceDetail
  },
  {
    path: '/dreamService/done',
    name: 'publicDreamServiceDone'
  },
  {
    path: '/registration',
    name: 'selfRegistration',
    component: SelfRegistration,
    children: [
      {
        path: 'userChoise',
        name: 'RegistrationChoise',
        component: RegistrationChoise,
        meta: {
          title: TITLE_PREFIX + 'Registrazione' + TITLE_SUFFIX
        }
      },
      {
        path: 'userData',
        name: 'RegistrationUserData',
        component: RegistrationUserData,
        meta: {
          title: TITLE_PREFIX + 'Registrazione' + TITLE_SUFFIX
        }
      },
      {
        path: 'privacy',
        name: 'RegistrationPrivacy',
        component: RegistrationPrivacy,
        meta: {
          title: TITLE_PREFIX + 'Registrazione login' + TITLE_SUFFIX
        }
      },
      {
        path: 'confirmation',
        name: 'RegistrationConfirmation',
        component: RegistrationConfirmation,
        props: true,
        meta: {
          title: TITLE_PREFIX + 'TYP Registrazione' + TITLE_SUFFIX
        }
      }
    ]
  },
  {
    path: '/private',
    name: 'private',
    component: PrivateArea,
    beforeEnter: function (to, from, next) {
      checkAuthentication(next, to)
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomeNew,
        meta: {
          title: TITLE_PREFIX + 'Homepage' + TITLE_SUFFIX,
          openCfp: false,
          openChat: false
        }
      },
      {
        path: 'selfreading',
        name: 'openselfreading',
        component: HomeNew,
        meta: {
          title: TITLE_PREFIX + 'Homepage' + TITLE_SUFFIX,
          openselfreading: true
        }
      },
      {
        path: 'chatbot/:clientCode',
        name: 'openChat',
        component: HomeNew,
        meta: {
          title: TITLE_PREFIX + 'Homepage' + TITLE_SUFFIX,
          openChat: true
        }
      },
      {
        path: 'owner-supplies',
        name: 'ownerSupplies',
        component: OwnerSupplies
      },
      // --> end new home page
      {
        path: 'myshop/:code',
        name: 'myshopdeeplink',
        component: MyShop
      },
      {
        path: 'myshop',
        name: 'myshop',
        component: MyShop
      },
      {
        path: 'openmyshop',
        name: 'openmyshop',
        component: HomeNew,
        meta: {
          title: TITLE_PREFIX + 'Homepage' + TITLE_SUFFIX,
          openMyShop: true
        }
      },
      {
        path: 'beyondenergy-alert',
        name: 'beyondenergy-alert',
        component: HomeNew,
        meta: {
          title: TITLE_PREFIX + 'Homepage' + TITLE_SUFFIX,
          beyondenergyAlert: true
        }
      },
      {
        path: 'cfp',
        name: 'opencfp',
        component: HomeNew,
        meta: {
          title: TITLE_PREFIX + 'CFP' + TITLE_SUFFIX,
          openCfp: true
        }
      },
      {
        path: 'home/payment-summary/:clientCode',
        name: 'paymentSummary',
        component: PaymentSummary
      },
      {
        path: 'home/payallbills/:clientOwnerCode',
        name: 'payAllBills',
        component: PayMultipleBills
      },
      {
        path: 'home/payallbills/:clientOwnerCode/:status',
        name: 'pdrPaymentResult',
        component: supplyPaymentResult
      },
      {
        path: 'home/promo/:id',
        name: 'promo',
        component: LandingPromo
      },
      {
        path: 'supply/:clientOwnerCode/:supplyCode',
        name: 'supply',
        component: Supply,
        children: [
          {
            path: 'details',
            name: 'supplyDetail',
            component: SupplyDetail,
            meta: {
              title: TITLE_PREFIX + 'Dettaglio fornitura' + TITLE_SUFFIX
            }
          },
          {
            path: 'bills/:idFattura',
            name: 'bills',
            component: BillDetail,
            meta: {
              title: TITLE_PREFIX + 'Dettaglio bolletta' + TITLE_SUFFIX
            }
          },
          {
            path: 'archive',
            name: 'billsArchive',
            component: billsArchive,
            meta: {
              title: TITLE_PREFIX + 'Archivio bollette' + TITLE_SUFFIX
            }
          },
          {
            path: 'pdr/:planId',
            name: 'pdrDetail',
            component: PdrDetail,
            meta: {
              title: TITLE_PREFIX + 'Dettaglio PDR' + TITLE_SUFFIX
            }
          },
          {
            path: 'payment/:status',
            name: 'supplyPayment',
            component: supplyPaymentResult
          },
          {
            path: 'bidgely',
            name: 'supplyBidgely',
            component: supplyBidgely,
            meta: {
              title: TITLE_PREFIX + 'Bidgely' + TITLE_SUFFIX
            }
          },
          {
            path: 'action',
            component: Template,
            children: [
              {
                path: 'parentalControl',
                name: 'parentalControlAction',
                component: ParentalControl
              },
              {
                path: 'fiber',
                name: 'fiberAction',
                component: FiberAction
              },
              {
                path: 'power',
                name: 'powerAction',
                component: Power,
                meta: {
                  title: TITLE_PREFIX + 'Variazione potenza' + TITLE_SUFFIX
                }
              },
              {
                path: 'payment',
                name: 'paymentAction',
                component: Payment,
                meta: {
                  title:
                    TITLE_PREFIX + 'Cambio Metodo di pagamento' + TITLE_SUFFIX
                }
              },
              {
                path: 'pay',
                name: 'payBill',
                component: PayBill,
                meta: {
                  title: TITLE_PREFIX + 'Pagamento' + TITLE_SUFFIX
                }
              },
              {
                path: 'paySupplyBills',
                name: 'paySupplyBills',
                component: PayMultipleBills,
                meta: {
                  title: TITLE_PREFIX + 'Paga bollette' + TITLE_SUFFIX
                }
              },
              {
                path: 'payment/newmethod',
                name: 'newPaymentMethod',
                component: NewPaymentMethod,
                meta: {
                  title:
                    TITLE_PREFIX + 'Cambio Metodo di pagamento' + TITLE_SUFFIX
                }
              },
              {
                path: 'counter',
                name: 'counterAction',
                component: Counter,
                meta: {
                  title: TITLE_PREFIX + 'Gestione contatore' + TITLE_SUFFIX
                }
              },
              {
                path: 'voltage',
                name: 'voltageAction',
                component: Voltage,
                meta: {
                  title: TITLE_PREFIX + 'Gestione tensione' + TITLE_SUFFIX
                }
              },
              {
                path: 'cadastral',
                name: 'cadastralAction',
                component: CadastralUser,
                meta: {
                  title:
                    TITLE_PREFIX + 'Dati catastali sottoscritto' + TITLE_SUFFIX
                }
              },
              {
                path: 'cadastral/data',
                name: 'cadastralData',
                component: CadastralData,
                meta: {
                  title: TITLE_PREFIX + 'Dati catastali identif' + TITLE_SUFFIX
                }
              },
              {
                path: 'cadastral/nodata',
                name: 'cadastralNoData',
                component: CadastralNoData,
                meta: {
                  title:
                    TITLE_PREFIX + 'Motivo mancata compilazione' + TITLE_SUFFIX
                }
              },
              {
                path: 'cadastral/success',
                name: 'cadastralSuccess',
                component: CadastralSuccess,
                meta: {
                  title: TITLE_PREFIX + 'Dati catastali TYP' + TITLE_SUFFIX
                }
              },
              {
                path: 'success/:type',
                name: 'promSuccess',
                component: PromotionSuccess,
                meta: {
                  title: TITLE_PREFIX + 'TYP Azione' + TITLE_SUFFIX
                }
              },
              {
                path: 'requestCredit',
                name: 'requestCredit',
                component: RequestCredit,
                meta: {
                  title: TITLE_PREFIX + 'Richiedi credito' + TITLE_SUFFIX
                }
              }
            ]
          },
          {
            path: ':type?/selfreading',
            name: 'selfReadingLight',
            component: SelfReadingLight,
            children: [
              {
                path: 'base',
                name: 'selfReadingLight_Base',
                component: SelfReadingLightBase,
                meta: {
                  title: TITLE_PREFIX + 'Autolettura' + TITLE_SUFFIX
                }
              },
              {
                path: 'base2',
                name: 'selfReadingLight_Base2',
                component: SelfReadingLightBase2,
                meta: {
                  title: TITLE_PREFIX + 'Autolettura Gas' + TITLE_SUFFIX
                }
              },
              {
                path: 'warning1',
                name: 'selfReadingLight_Warning1',
                component: SelfReadingLightWarning1,
                meta: {
                  title: TITLE_PREFIX + 'Autolettura attenzione' + TITLE_SUFFIX
                }
              },
              {
                path: 'warning2',
                name: 'selfReadingLight_Warning2',
                component: SelfReadingLightWarning2,
                meta: {
                  title:
                    TITLE_PREFIX + 'Autolettura attenzione 2' + TITLE_SUFFIX
                }
              },
              {
                path: 'contactme',
                name: 'selfReadingLight_Contactme',
                component: SelfReadingLightContactme,
                meta: {
                  title: TITLE_PREFIX + 'Autolettura carica foto' + TITLE_SUFFIX
                }
              }
            ]
          },
          {
            path: 'punDetail',
            name: 'punDetail',
            component: PunDetail,
            meta: {
              title: TITLE_PREFIX + 'Dettaglio PUN' + TITLE_SUFFIX
            }
          },
          {
            path: 'thankyou',
            name: 'selfReadingLight_Thankyou',
            component: SelfReadingLightThankyou,
            meta: {
              title: TITLE_PREFIX + 'TYP Autolettura' + TITLE_SUFFIX
            }
          }
        ]
      },
      {
        path: 'service-profile',
        name: 'serviceProfile',
        component: ServiceProfile,
        beforeEnter: function (to, from, next) {
          switchPageService(to, from, next)
        },
        meta: {
          title: TITLE_PREFIX + 'Servizi Sorgenia'
        },
        children: [
          {
            path: 'entry-page',
            name: 'entryPage',
            component: EntryPage,
            meta: {
              title: TITLE_PREFIX + 'Servizi Sorgenia'
            }
          },
          {
            path: 'service-section',
            name: 'serviceSection',
            component: ServiceSection,
            meta: {
              title: TITLE_PREFIX + 'Servizi Sorgenia'
            }
          }
        ]
      },
      {
        path: 'account',
        name: 'account',
        component: Account,
        beforeEnter: function (to, from, next) {
          switchPageAccount(to, from, next)
        },
        meta: {
          title: TITLE_PREFIX + 'Account' + TITLE_SUFFIX
        }
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        beforeEnter: function (to, from, next) {
          switchPageAccount(to, from, next)
        },
        children: [
          {
            path: 'consumption',
            name: 'consumptionProfile',
            component: ConsumptionProfile,
            meta: {
              title: TITLE_PREFIX + 'Profilo 2.0 - Home'
            }
          },
          {
            path: 'information',
            name: 'informationProfile',
            component: InformationProfile,
            meta: {
              title: TITLE_PREFIX + 'Profilo 2.0 - Dati Personali'
            }
          },
          {
            path: 'documents',
            name: 'documentsProfile',
            component: DocumentsProfile,
            meta: {
              title: TITLE_PREFIX + 'Profilo 2.0 - Contratti e Documenti'
            }
          },
          {
            path: 'informative',
            name: 'informativeProfile',
            component: InformativeProfile,
            meta: {
              title: TITLE_PREFIX + 'Profilo 2.0 - Informative e Consensi'
            }
          }
        ]
      },
      {
        path: 'fiber-new',
        name: 'fiberOffering',
        component: SelectAddressResBus
      },
      {
        path: 'fiber-new/speed',
        name: 'speedChoice',
        component: SpeedChoice,
        meta: {
          title: TITLE_PREFIX + 'Scelta Listino Cliente' + TITLE_SUFFIX
        }
      },
      {
        path: 'fiber-new/offert',
        name: 'fiberFlow',
        component: FiberFlow
      },
      {
        path: 'fiber-new/offert-bus',
        name: 'fiberFlowBus',
        component: FiberFlowBus
      },
      {
        path: 'fiber-new/uploaded',
        name: 'uploaded',
        component: SuccessUpload
      },
      {
        path: 'fiber-new/request-sent',
        name: 'fiberNewRequestSent',
        component: fiberNewRequestSent
      },
      {
        path: 'fiber-new/request-bus-sent',
        name: 'fiberNewRequestBusSent',
        component: fiberNewRequestBusSent
      },
      {
        path: 'fiber-new/subscribed',
        name: 'subscribed',
        component: SuccessSubscribedd
      },
      {
        path: 'request-sent',
        name: 'fiberRequestSent',
        component: FiberRequestSent
      },
      {
        path: 'gas/offering',
        name: 'gasOffering',
        component: GasFlow,
        beforeEnter: function (to, from, next) {
          switchToBusOrResUpselling(to, from, next)
        },
        props: { type: 'gas' }
      },
      {
        path: 'gas/offering/offers',
        name: 'offersgas',
        component: CommodityOffers,
        props: { type: 'gas' }
      },
      {
        path: 'gas/request-sent',
        name: 'requestSentgas',
        component: RequestSentCommodity
      },
      {
        path: 'gas/success-upload',
        name: 'successUploadComm',
        component: SuccessUploadCommodity
      },
      {
        path: 'ele/offering',
        name: 'eleOffering',
        component: EleFlow,
        beforeEnter: function (to, from, next) {
          switchToBusOrResUpselling(to, from, next)
        },
        props: { type: 'ele' }
      },
      {
        path: 'ele/offering/offers',
        name: 'offersele',
        component: CommodityOffers,
        props: { type: 'ele' }
      },
      {
        path: 'ele/request-sent',
        name: 'requestSentele',
        component: RequestSentCommodity
      },
      {
        path: 'gas/subscribed',
        name: 'subscribedgas',
        component: SuccessSubscribeddComm
      },
      {
        path: 'ele/subscribed',
        name: 'subscribedele',
        component: SuccessSubscribeddComm
      },
      {
        path: 'ele/success-upload',
        name: 'successUploadComm',
        component: SuccessUploadCommodity
      },
      {
        path: 'gas/offering',
        name: 'gasOfferingBUS',
        component: GasOffering,
        props: { type: 'gas' }
      },
      {
        path: 'ele/offering',
        name: 'eleOfferingBUS',
        component: EleOffering,
        props: { type: 'ele' }
      },
      {
        path: 'gas/newtracker',
        name: 'newTracker',
        component: NewTracker
      },
      {
        path: 'gas/successpage',
        name: 'successPage',
        component: SuccessPage
      },
      {
        path: 'changeoffer',
        name: 'changeOffer',
        component: ChangeOfferIndex,
        children: [
          {
            path: ':clientCode/:prCode/:productCode?/:tariffList?',
            name: 'changeOfferCommodity',
            component: ChangeOfferCommodity,
            meta: {
              title: TITLE_PREFIX + 'Cambio offerta dettagli' + TITLE_SUFFIX
            }
          },
          {
            path: 'result',
            name: 'resultChangeOffer',
            component: ResultChangeOffer,
            meta: {
              title: TITLE_PREFIX + 'Cambio offerta TYP' + TITLE_SUFFIX
            }
          }
        ]
      },
      {
        path: 'afterthought',
        name: 'afterthought',
        component: Afterthought
      },
      {
        path: 'support',
        component: SupportTemplate,
        children: Support
      },
      {
        path: 'tracking',
        name: 'tracking',
        component: () => import('./Template.vue'),
        children: [
          {
            path: 'fiber/:clientOwnerCode/:supplyCode',
            name: 'trackingFiber',
            component: TrackingFiber
          },
          {
            path: 'commodity/:clientOwnerCode/:supplyCode',
            name: 'trackingCommodity',
            component: TrackingCommodity
          },
          {
            path: 'photovoltaic',
            name: 'trackingPhotovoltaic',
            component: TrackingPhotovoltaic
          }
        ]
      },
      {
        path: 'loyalty',
        component: Template,
        beforeEnter: function (to, from, next) {
          checkMultipleMainClients(to, from, next)
          checkAlertError(to, from, next)
        },
        children: [
          {
            path: '/',
            name: 'loyaltyPage',
            component: LoyaltyPage
          },
          {
            path: 'catalogue',
            name: 'catalogue',
            component: Catalogue,
            beforeEnter: function (to, from, next) {
              checkAlertError(to, from, next)
            }
          },
          {
            path: 'greenersFriendCode',
            name: 'GreenersFriendCode',
            component: GreenersFriendCode,
            meta: {
              title: TITLE_PREFIX + 'Codice Amico Greeners'
            }
          },
          {
            path: 'catalogue/:id',
            name: 'catalogueDetails',
            component: BonusDetail
          },
          {
            path: 'survey/:id',
            name: 'survey',
            component: Survey
          },
          {
            path: 'upload',
            name: 'uploadPhoto',
            component: UploadPhotoPage
          },
          {
            path: 'upload/reward',
            name: 'successUpload',
            component: SuccessUploadPage,
            beforeEnter: function (to, from, next) {
              checkAlertError(to, from, next)
            }
          },
          {
            path: 'survey/reward/:id',
            name: 'surveyReward',
            component: SurveyRewardPage,
            beforeEnter: function (to, from, next) {
              checkAlertError(to, from, next)
            }
          },
          {
            path: 'video',
            name: 'videoPage',
            component: VideoPage
          },
          {
            path: 'video/feedback',
            name: 'videoFeedbackPage',
            component: VideoFeedbackPage
          },
          {
            path: 'video/reward',
            name: 'videoRewardPage',
            component: VideoRewardPage,
            beforeEnter: function (to, from, next) {
              checkAlertError(to, from, next)
            }
          },
          {
            path: 'landing',
            name: 'introPage',
            component: LandingPage,
            beforeEnter: function (to, from, next) {
              checkMultipleMainClients(to, from, next)
            }
          },
          {
            path: 'shipping',
            name: 'shippingPage',
            component: ShippingFormPage
          },
          {
            path: 'shipping/confirm/:type',
            name: 'shippingConfirm',
            component: ShipConfirmPage,
            beforeEnter: function (to, from, next) {
              checkAlertError(to, from, next)
            }
          },
          {
            path: 'landing/becomeGreener',
            name: 'becomeGreenerPage',
            component: BecomeGreenerPage
          },
          {
            path: 'landing/joinTeam',
            name: 'joinTeamPage',
            component: JoinTeamPage
          },
          {
            path: 'howitworks',
            name: 'howItWorks',
            component: HowItWorksPage
          },
          {
            path: 'howitworks',
            name: 'IntroPage',
            component: LandingPage
          },
          {
            path: 'howitworks1',
            name: 'howItWorks1',
            component: HowItWorksPage1
          },
          {
            path: 'coins',
            name: 'coinsPage',
            component: CoinsPage,
            beforeEnter: function (to, from, next) {
              checkAlertError(to, from, next)
            }
          },
          {
            path: 'rules',
            name: 'rulesPage',
            component: RulesPage
          },
          {
            path: 'rules/success',
            name: 'rulesSuccess',
            component: RulesSuccessPage
          },
          {
            path: 'gifts/bicycle',
            name: 'bicycleReward',
            component: BicycleReward
          },
          {
            path: 'gifts/shipping/:type/:id',
            name: 'giftShipping',
            component: GiftShippingPage,
            beforeEnter: function (to, from, next) {
              checkAlertError(to, from, next)
            }
          },
          {
            path: 'gifts/shippingEmail/:id',
            name: 'giftEmailShipping',
            component: GiftEmailShippingPage,
            beforeEnter: function (to, from, next) {
              checkAlertError(to, from, next)
            }
          },
          {
            path: 'gifts',
            name: 'giftsMainPage',
            component: GiftsMainPage,
            beforeEnter: function (to, from, next) {
              checkAlertError(to, from, next)
            }
          },
          {
            path: 'gifts/welcomegift',
            name: 'welcomeGift',
            component: WelcomeGiftPage
          },
          {
            path: 'gifts/iwgift',
            name: 'iwGift',
            component: IWGiftPage,
            beforeEnter: function (to, from, next) {
              checkAlertError(to, from, next)
            }
          },
          {
            path: 'gifts/giftcode/result',
            name: 'codeResult',
            component: CodeResultPage
          },
          {
            path: 'gifts/giftcode',
            name: 'giftCode',
            component: GiftCodePage
          },
          {
            path: 'gifts/details/:id',
            name: 'giftDetailsPage',
            component: GiftDetailsPage
          },
          {
            path: 'gifts/promote',
            name: 'promote',
            component: PromotionPage,
            beforeEnter: function (to, from, next) {
              checkAlertError(to, from, next)
            }
          },
          {
            path: 'quiz/:id',
            name: 'quiz',
            component: Quiz
          },
          {
            path: 'quiz/reward/:id',
            name: 'successQuiz',
            component: SuccessQuiz,
            beforeEnter: function (to, from, next) {
              checkAlertError(to, from, next)
            }
          },
          {
            path: 'registered/:id',
            name: 'registered',
            component: Registered
          }
        ]
      },
      {
        path: 'welcome-kit',
        name: 'welcomekit',
        component: WelcomeKit
      },
      {
        path: 'welcome-kit/clean-energy',
        name: 'cleanEnergy',
        component: CleanEnergy
      },
      {
        path: 'welcome-kit/clean-energy-new',
        name: 'cleanEnergyNew',
        component: CleanEnergyNew
      },
      {
        path: 'welcome-kit/bill-sorgenia',
        name: 'billSorgenia',
        component: BillSorgenia
      },
      {
        path: 'welcome-kit/standard/:id',
        name: 'standardWelcomeKit',
        component: StandardWelcomeKit
      },
      {
        path: 'tutorial',
        name: 'tutorial',
        component: Tutorial
      },
      {
        path: 'tutorial/standard/:id',
        name: 'standardTutorial',
        component: StandardTutorial
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: Feedback
      },
      {
        path: 'feedback/survey/:id',
        name: 'feedback-survey',
        component: FeedbackSurvey
      },
      {
        path: 'checkup',
        name: 'deeplinkCheckup',
        component: HomeNew,
        beforeEnter: function (to, from, next) {
          setTitlePageWithoutHeader(to, from, next, 'Checkup Energetico')
        }
      },
      {
        path: 'photovoltaic/:acuId/:siteId/dashboard',
        name: 'dashboardFotovoltaico',
        component: DashboardFotovoltaico
      },
      {
        path: 'photovoltaic/:acuId/:siteId/production',
        name: 'fotovoltaicoProduzione',
        component: FotovoltaicoProduzione
      },
      {
        path: 'photovoltaic/:acuId/:siteId/consumption',
        name: 'fotovoltaicoConsumo',
        component: FotovoltaicoConsumo
      },
      {
        path: 'photovoltaic/:acuId/:siteId/savings',
        name: 'fotovoltaicoRisparmio',
        component: FotovoltaicoRisparmio
      },
      {
        path: 'photovoltaic/:acuId/:siteId/impact',
        name: 'fotovoltaicoImpatto',
        component: FotovoltaicoImpatto
      }
    ]
  },
  {
    path: '/logout',
    beforeEnter() {
      store.dispatch('session/logout')
    }
  },
  {
    path: '*',
    redirect: { name: 'home' }
  }
]

const scrollBehavior = (to, from, savedPosition) => {
  return { x: 0, y: 0 }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes
})

export default router
