<template>
  <div class="std-side-padding mb-12 pt-10">
    <TcVars />
    <div class="result">
      <template v-if="$route.params.status === 'ok'">
        <img src="../../../assets/icons/Illo.svg" />
        <h4>Richiesta inviata</h4>
        <h5 class="border">
          Abbiamo ricevuto correttamente la richiesta di attivazione della nuova
          offerta
        </h5>
        <h5 class="medium">Cosa faremo noi?</h5>
        <h5>
          Ci occuperemo di aggiornare automaticamente la tua attuale offerta che
          sarà attiva dal primo giorno del prossimo mese e ti invieremo una
          email di conferma con la documentazione contrattuale in allegato.
        </h5>
        <h5 class="medium">Cosa farai tu?</h5>
        <h5>
          Potrai vedere le condizioni contrattuali che riceverai per email.
        </h5>
      </template>
      <template v-if="$route.params.status === 'alreadySigned'">
        <img src="../../../assets/icons/Illo.svg" />
        <h4>Richiesta già inviata</h4>
        <h5>
          La richiesta di attivazione della promozione riservata a questa utenza
          è già stata effettuata.
        </h5>
      </template>
      <template v-if="$route.params.status === 'expired'">
        <img src="../../../assets/icons/IlloError.svg" />
        <h4>Offerta non più valida</h4>
        <h5>
          La promozione riservata all’utenza
          {{ $filters.capitalize($route.params.address) }} è scaduta.<br />Per
          maggiori info vai in
          <span @click="$router.push({ name: 'SupportPage' })"
            >Assistenza.</span
          >
        </h5>
      </template>
      <template v-if="$route.params.status === 'offerNotFound'">
        <img src="../../../assets/icons/IlloError.svg" />
        <h4>Offerta non trovata</h4>
        <h5>Non è stato possibile trovare l’offerta, torna all’homepage.</h5>
      </template>
      <v-btn
        color="primary"
        class="mt-12"
        width="279"
        height="54"
        @click="$router.push({ name: 'home' })"
        >Torna all’Homepage</v-btn
      >
    </div>
  </div>
</template>
<script>
import TcVars from '@/components/tcTracker/TcVars'

export default {
  name: 'resultPage',
  components: {
    TcVars
  }
}
</script>
<style lang="scss" scoped>
.result {
  max-width: 652px;
  .border {
    border-bottom: 1px solid #eaeef5;
    padding-bottom: 24px;
  }
  img {
    margin-bottom: 24px;
  }
  h4 {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 1px;
    margin-bottom: 8px;
  }
  h5 {
    color: black;
    line-height: 1.4;
    letter-spacing: 1px;
    &.medium {
      font-size: 18px;
      font-weight: bold;
      margin-top: 24px;
    }
    span {
      font-weight: bold;
      color: $srg-blue;
      cursor: pointer;
    }
  }
}
</style>
