<template>
  <div class="private-area" :class="{ 'header-padding': !isMyShop }">
    <message-center v-if="mainClient && !mainClient?.isOnlyFV"></message-center>
    <KaleyraDialog></KaleyraDialog>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import KaleyraDialog from './kaleyra/KaleyraDialog.vue'
// import axios from 'axios'
import MessageCenter from './messageCenter/MessageCenter.vue'
import GenErr from '@/js/genericErrors'

export default {
  data: () => {
    return {
      user: null,
      isFiberFlow: false,
      isMyShop: false,
      commodityFlow: false
    }
  },
  computed: {
    ...mapGetters('account', ['mainClient'])
  },
  // async beforeMount () {
  //   axios
  //     .get('/user')
  //     .then(resp => {
  //       console.log(resp)
  //     })
  //     // USING the retry strategy. all errors are centrally managed
  //     // .catch(err => {
  //     //   console.log(err)
  //     //   this.$store.dispatch('session/logout')
  //     //   confirm('Impossibile contattare il server: la sessione verrà interrotta. Riprova facendo un nuovo login.')
  //     // })
  // }
  watch: {
    $route(to) {
      this.isFiberFlow = to.name === 'fiberFlow'
      this.isMyShop = ['myshop', 'myshopdeeplink', 'openmyshop'].includes(
        to.name
      )
      this.commodityFlow = ['gasOffering', 'eleOffering'].includes(to.name)
    },
    async mainClient(mainClient) {
      if (!mainClient.isOnlyFV) {
        let objkaleyra = await this.kaleyraAuth(mainClient.clientOwnerCode)
        try {
          window.kaleyra.connect(objkaleyra.userId, objkaleyra.accessToken)
        } catch (error) {
          throw GenErr.showErrorMessage()
        }
      }
    }
  },
  created: function () {
    localStorage.setItem('videoCallBooked', false)
    this.isFiberFlow = this.$route.name === 'fiberFlow'
    this.isMyShop = ['myshop', 'myshopdeeplink', 'openmyshop'].includes(
      this.$route.name
    )
    this.commodityFlow = ['gasOffering', 'eleOffering'].includes(
      this.$route.name
    )
  },
  methods: {
    ...mapActions('session', ['kaleyraAuth'])
  },
  components: { MessageCenter, KaleyraDialog }
}
</script>
<style scoped>
.header-padding {
  height: 100%;
  padding-top: 93px;
}
</style>
