<template>
  <v-dialog
    v-model="dialogOpen"
    persistent
    max-width="558"
    overlay-opacity="0.6"
    content-class="montlhyEstimateModalOpen"
  >
    <div class="montlhyEstimateModalOpen">
      <div class="d-flex align-center justify-space-between mb-10">
        <div class="SubheadingL" v-html="titleModal" />
        <img
          class="close"
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          @click="closeDialog"
        />
      </div>
      <div class="BodyL body" v-html="bodyModal" />
      <div class="LabelM" v-html="label" />
      <v-text-field
        ref="annualConsumption"
        @input="handleInput"
        v-model="annualConsumption"
        :error-messages="errArray"
        :placeholder="example"
        persistent-placeholder
      />
      <div class="cta">
        <MYSButton
          :disabled="checkInputEmpty"
          :loading="buttonLoading"
          buttonClass="primaryButton"
          :buttonText="ctaCopy"
          @buttonClick="checkInput"
        />
      </div>
    </div>
  </v-dialog>
</template>
<script>
import MYSButton from '../../../../components/global/MYSButton.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, between } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'MontlhyEstimateModal',
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  props: {
    montlhyEstimateModalOpen: {
      type: Boolean,
      required: true
    },
    title: {
      type: String
    },
    body: {
      type: String
    },
    label: {
      type: String
    },
    offerType: {
      type: String
    },
    example: {
      type: String
    },
    errorCopy: {
      type: String
    },
    ctaCopy: {
      type: String
    }
  },
  data() {
    return {
      annualConsumption: '',
      buttonLoading: false,
      errArray: []
    }
  },
  components: {
    MYSButton
  },
  computed: {
    dialogOpen: {
      get() {
        return this.montlhyEstimateModalOpen
      },
      set(val) {
        this.$emit('update:montlhyEstimateModalOpen', val)
      }
    },
    labelModal() {
      return this.label !== null ? this.label : ''
    },
    titleModal() {
      return this.title !== null ? this.title : ''
    },
    bodyModal() {
      return this.body !== null ? this.body : ``
    },
    checkInputEmpty() {
      if (this.annualConsumption === '') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions('upsellingComm', ['checkConsumptionValue']),
    handleInput() {
      this.v$.annualConsumption.$touch()
      this.errArray = []
    },
    inputErrors() {
      if (!this.v$.annualConsumption.$invalid) {
        this.errArray = []
      }

      if (
        this.v$.annualConsumption.required.$invalid === true ||
        this.v$.annualConsumption.between.$invalid === true
      ) {
        this.errArray = [this.errorCopy]
      }
    },
    async checkInput() {
      this.buttonLoading = true
      let params = {
        annualEleConsumption:
          this.offerType === 'ele' ? this.annualConsumption : null,
        annualGasConsumption:
          this.offerType === 'gas' ? this.annualConsumption : null
      }
      try {
        if (
          this.v$.annualConsumption.between.$invalid === true ||
          this.v$.annualConsumption.required.$invalid === true
        ) {
          throw new Error('input non valido')
        }
        await this.checkConsumptionValue(params)
        this.$emit('checkAnnualConsumption', this.annualConsumption)
        this.closeDialog()
      } catch (error) {
        this.errArray = [this.errorCopy]
        console.error('Errore in checkConsumptionValue:', error)
        this.buttonLoading = false
      }
    },
    resetErrors() {
      this.v$.$reset()
      this.errArray = []
    },
    closeDialog() {
      this.annualConsumption = ''
      this.buttonLoading = false
      this.resetErrors()
      this.$emit('update:montlhyEstimateModalOpen', false)
    }
  },
  validations: {
    annualConsumption: {
      required,
      between: between(1, 10000)
    }
  }
}
</script>

<style lang="scss" scoped>
.montlhyEstimateModalOpen {
  background: white;
  border-radius: 20px !important;
  padding: 40px;
  .close {
    cursor: pointer;
  }
  .image {
    width: 478px;
  }
  .body {
    width: 478px;
  }
  .LabelM {
    margin-top: 24px;
    color: $neutri-neutral-50;
    text-transform: uppercase;
  }
  .cta {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
}
</style>
<style lang="scss">
.montlhyEstimateModalOpen {
  .error--text {
    .v-text-field__slot {
      input {
        color: red;
      }
    }
    .v-messages__message {
      display: flex;
      align-items: center;
      margin-top: 5px;
      &::before {
        margin-right: 5px;
        content: url('@/assets/icons/Error.svg');
      }
    }
  }
}
</style>
