<template>
  <div v-if="!detail.address">
    <v-row class="std-side-padding pa-12" align="center">
      <v-col class="text-center">
        <v-progress-circular :size="50" indeterminate></v-progress-circular>
      </v-col>
    </v-row>
  </div>
  <div class="pianiDiRientro std-side-padding mt-12" v-else>
    <v-row class="std-side-padding">
      <v-col class="pt-0">
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0 pt-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <TcVars />
    <v-row>
      <v-col class="pianiDiRientro__data pb-0">
        <v-row class="HeadingsTitleXXL mb-6">
          Piano di rientro
          {{ pianoAttivo[0]?.planEndDate.replaceAll('/', '.') }}
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="rateScadute.length > 0 && !checkInVerifica" class="mt-0 mb-4">
      <v-col class="pianiDiRientro__lista-rate-scadute pt-0">
        <span
          class="CaptionXL pianiDiRientro__alert upper"
          v-if="rateScadute.length <= 1"
        >
          {{ rateScadute.length }} Rata scaduta</span
        >
        <span class="CaptionXL pianiDiRientro__alert upper" v-else>
          {{ rateScadute.length }} Rate scadute</span
        >
        <span class="BodyXL pl-6">
          Scadenza il
          {{
            prossimaScadenzaRata.instalmentDueDate.replaceAll('/', '.')
          }}</span
        >
      </v-col>
    </v-row>
    <v-row v-else class="mt-0 mb-4">
      <v-col class="pt-0">
        <div class="CaptionXL upper">
          {{
            consts.TRANSLATE_STATUS_PDR[pianoAttivo[0]?.planStatus] === 'Attiva'
              ? 'In corso'
              : consts.TRANSLATE_STATUS_PDR[pianoAttivo[0]?.planStatus]
          }}
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="pianiDiRientro__id-pdr mb-6 pt-0">
        <span class="CaptionXL upper">
          id pdr:
          <span class="BodyL"> {{ pianoAttivo[0]?.planId }} </span></span
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pianiDiRientro__totale-rate mb-6" cols="8">
        <div class="pianiDiRientro__amount">
          <div class="pianiDiRientro__desc">
            <p class="HeadingsTitleL">Da pagare</p>
            <p class="BodyL">Totale Spesa</p>
            <p class="BodyL">Hai già pagato</p>
          </div>
          <div class="pianiDiRientro__price text-right">
            <p class="HeadingsTitleL-Upper">
              € {{ getTotal(getResidualAmountToPay()) }}
            </p>
            <p class="HeadingsTitleL-Upper">
              € {{ getTotal(getTotalAmountToPay()) }}
            </p>
            <p class="HeadingsTitleL-Upper">
              €
              {{
                getTotal('', getTotalAmountToPay(), getResidualAmountToPay())
              }}
            </p>
          </div>
        </div>
        <div class="pianiDiRientro__lista">
          <v-row class="d-flex">
            <v-col class="pianiDiRientro__box-lista pb-0 pl-0" cols="2">
              <span class="BodyM">N° rata</span>
            </v-col>
            <v-col class="pianiDiRientro__box-lista pb-0" cols="3">
              <span class="BodyM">Scadenza</span>
            </v-col>
            <v-col class="pianiDiRientro__box-lista pb-0" cols="5">
              <span class="BodyM">Stato rata</span>
            </v-col>
            <v-col
              class="pianiDiRientro__box-lista pb-0 align-end pr-0"
              cols="2"
            >
              <span class="BodyM">Importo</span>
            </v-col>
          </v-row>
          <v-row
            class="pianiDiRientro__riga-lista"
            v-for="(rate, i) in pianoAttivo[0]?.instalments"
            :key="i"
          >
            <v-col class="pianiDiRientro__box-lista pl-0" cols="2">
              <span class="BodyL-Strong">{{ rate.instalmentCount }}</span>
            </v-col>
            <v-col class="pianiDiRientro__box-lista" cols="3">
              <span class="BodyL-Strong">{{
                $filters.formatDateSplitJoin(rate.instalmentDueDate)
              }}</span>
            </v-col>
            <v-col class="pianiDiRientro__box-lista" cols="5">
              <span
                v-if="rate.checkPaymentInstalment"
                class="BodyL-Strong upper"
                >In verifica
              </span>
              <span
                v-else
                :class="`BodyL-Strong upper ${
                  rate.instalmentStatus === consts.STATUS_PDR.EXPIRED
                    ? 'pianiDiRientro__alert'
                    : ''
                }`"
                >{{ consts.TRANSLATE_STATUS_PDR[rate.instalmentStatus] }}
              </span>
            </v-col>
            <v-col class="pianiDiRientro__box-lista align-end pr-0" cols="2">
              <span class="BodyL-Strong">
                €
                {{ getTotal(rate.amount) }}</span
              >
            </v-col>
          </v-row>
        </div>
        <div class="pianiDiRientro__bills-included">
          <div class="HeadingsTitleXL mb-6">
            Bollette incluse nel piano di rientro
          </div>

          <v-row
            class="pianiDiRientro__list-bills-included"
            v-for="(bolletta, i) in this.listaFatture"
            :key="i"
          >
            <v-col class="pianiDiRientro__bills-lista pl-0" cols="3">
              <span class="BodyM">N° fattura</span>
              <span class="BodyL-Strong">{{ bolletta.invoiceId }}</span>
            </v-col>
            <v-col
              class="pianiDiRientro__bills-lista"
              :cols="bolletta.pod || bolletta.pdr ? '5' : '8'"
            >
              <span class="BodyM">Utenza</span>
              <span class="BodyL-Strong"
                >{{ bolletta.street }} {{ bolletta.number }} {{ bolletta.city }}
              </span>
            </v-col>
            <v-col
              class="pianiDiRientro__bills-lista"
              cols="3"
              v-if="bolletta.pod || bolletta.pdr"
            >
              <span class="BodyM">POD/PDR</span>
              <span class="BodyL-Strong">
                {{ bolletta.pod || bolletta.pdr }}</span
              >
            </v-col>
            <v-col class="pianiDiRientro__bills-lista align-end pr-0" cols="1">
              <v-img
                :src="
                  require(`../../assets/icons/${path(bolletta.commodity)}.svg`)
                "
                height="32"
                width="32"
                contain
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- RF_19 & RF_6=> Inibire il click sulla CTA paga ora quando vi è la presenza di titoli. -->
      <v-col class="pianiDiRientro__summary" cols="4">
        <v-btn
          color="primary"
          x-large
          class="v-btn--shadowed mb-8"
          @click="openDialog()"
          :disabled="btnPay"
          >Paga Rate</v-btn
        >
        <div class="pianiDiRientro__box">
          <span class="HeadingsTitleXL mb-6">Informazioni</span>
          <span class="BodyL mb-2">Intestatario</span>
          <span class="BodyL-Strong mb-6">{{ getIntestatario() }}</span>
          <span class="BodyL mb-2">Data inizio</span>
          <span class="BodyL-Strong mb-6">{{
            $filters.formatDateSplitJoin(pianoAttivo[0]?.planStartDate)
          }}</span>
          <span class="BodyL mb-2">Data fine</span>
          <span class="BodyL-Strong mb-6">{{
            $filters.formatDateSplitJoin(pianoAttivo[0]?.planEndDate)
          }}</span>
          <span class="BodyL mb-2">N° rate</span>
          <span class="BodyL-Strong mb-6"
            >{{ pianoAttivo[0]?.instalments.length }}
          </span>
          <span class="BodyL mb-2">Metodo di pagamento</span>
          <span class="BodyL-Strong">
            {{ paymentTypeLabel(pianoAttivo[0]?.planPaymentMethod) }}</span
          >
        </div>
      </v-col>
    </v-row>
    <DialogPagaRate
      v-model="dialogPayRatePdr"
      source="Dettaglio PDR"
      :pdrAttivo="pianoAttivo[0]"
      :clientDetails="clientDetails"
      :intestatario="getIntestatario()"
      :dialogKey="
        checkFromSuppliesList ? pianoAttivo.planId : $route.params.planId
      "
    ></DialogPagaRate>
  </div>
</template>
<script>
import TcVars from '@/components/tcTracker/TcVars'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { capitalize, getTotal } from '../../js/utils'
import GenErr from '@/js/genericErrors'
import DialogPagaRate from './DialogPagaRate'
import consts from '@/js/constants'
import { paymentTypeLabel } from '@/js/constants'

export default {
  name: 'pdrDetail',
  data() {
    return {
      rateScadute: [],
      getTotal: getTotal,
      pianoAttivo: '',
      consts: consts,
      paymentTypeLabel: paymentTypeLabel,
      prossimaScadenzaRata: '',
      listaFatture: [],
      detail: {},
      dialogPayRatePdr: false,
      checkAbilitation: false,
      checkFromSuppliesList: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.checkFromSuppliesList = from.path.includes('/private/owner-supplies')
    })
  },
  methods: {
    ...mapActions('payment', ['getPdrInfo']),
    ...mapActions('analytics', ['trackTapEvent']),

    getRateScadute() {
      // ricerca rate scadute
      this.pianoAttivo.forEach((item) => {
        item.instalments.forEach((instalment) => {
          if (instalment.instalmentStatus === consts.STATUS_PDR.EXPIRED) {
            this.rateScadute.push(instalment)
            return
          }
        })
      })
    },
    openDialog() {
      this.trackTapEvent({
        name: 'payment_started',
        params: {
          service_type:
            this.supplyDetail[
              this.$route.params.supplyCode
            ].serviceType.toLowerCase(),
          component: 'cta',
          payment_item: 'pdr',
          screen_name: 'MySorgenia - Dettaglio PDR'
        }
      })
      this.trackTapEvent({
        name: 'PDRdetail_PDRpayment_tap'
      })
      this.dialogPayRatePdr = true
      this.checkAbilitation = true
    },

    dateRataRecentExpiring() {
      // ricerca rata con scadenza pià vicina
      this.prossimaScadenzaRata = this.pianoAttivo[0].instalments.reduce(
        (maxRate, rate) => {
          const currentDueDate = new Date(rate.instalmentDueDate)
          const maxDueDate = new Date(maxRate.instalmentDueDate)
          return currentDueDate < maxDueDate ? rate : maxRate
        },
        this.pianoAttivo[0].instalments[0]
      )
    },

    getTotalAmountToPay() {
      // totale spesa
      return this.pianoAttivo[0]?.instalments.reduce((acc, rata) => {
        return acc + rata.amount
      }, 0)
    },

    checkInVerifica() {
      return this.pianoAttivo[0]?.instalments.find(
        (rata) => rata.checkPaymentInstalment
      )
    },

    getResidualAmountToPay() {
      // totale residuo da pagare
      return this.pianoAttivo[0]?.instalments.reduce((acc, rata) => {
        let total = acc
        if (!rata.checkPaymentInstalment) {
          total = acc + rata.residualInstalmentAmount
        }
        return total
      }, 0)
    },

    path(sup) {
      // immagine della commodity in base al valore
      sup = sup.toUpperCase()
      let service = ''
      if (sup === 'ELE') {
        service = 'light'
      } else if (sup === 'GAS') {
        service = 'fire'
      } else {
        service = 'wifi'
      }
      return `ic-${service}-blu`
    },

    getIntestatario() {
      let intestatario = ''
      if (this.clientDetails.clientType === 'BUS') {
        intestatario = this.clientDetails.businessName
      } else {
        intestatario =
          this.clientDetails.name + ' ' + this.clientDetails.surname
      }
      return intestatario
    },

    getPianoAttivo(pdrList) {
      // ricerca piano attivo per prCode
      if (!pdrList) {
        this.pianoAttivo = pdrList.filter(
          (point) => point.planId === this.$route.params.planId
        )
      } else {
        this.pianoAttivo = pdrList
      }
    },

    getBolletteForPdr() {
      // ricerca bollette per prCode
      this.pianoAttivo[0].instalments.forEach((rata) => {
        rata.invoicesList.forEach((fattura) => {
          if (
            this.listaFatture.find((fat) => fat.invoiceId === fattura.invoiceId)
          ) {
            return false
          } else {
            this.listaFatture.push(fattura)
          }
        })
      })
    }
  },

  mounted() {
    this.getPdrInfo({
      clientCode: this.$route.params.clientOwnerCode,
      prCode: this.$route.params.supplyCode
    })
      .then((pdrList) => {
        this.getPianoAttivo(pdrList.item)
        this.getRateScadute()
        this.dateRataRecentExpiring()
        this.getBolletteForPdr()
        this.checkInVerifica()
        this.trackTapEvent({
          name: 'pdr_detail_viewed',
          params: {
            service_type:
              this.supplyDetail[
                this?.$route.params.supplyCode
              ]?.serviceType.toLowerCase()
          }
        })
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  computed: {
    ...mapGetters('account', ['clientDetails']),
    ...mapGetters('payment', ['getInfoPiani']),
    ...mapGetters('supply', ['supplyDetail']),

    breadcrumbsItems() {
      let res = []
      if (this.detail && this.detail.address) {
        let address = this.detail ? this.detail.address : ''
        address = capitalize(address.toLowerCase())
        res = [
          { to: { name: 'home' }, text: 'Home' },
          { to: { name: 'ownerSupplies' }, text: 'Utenze', exact: true },
          {
            to: {
              name: 'supplyDetail',
              params: {
                clientOwnerCode: this.$route.params.clientOwnerCode,
                supplyCode: this.$route.params.supplyCode
              }
            },
            text: address,
            exact: true
          },
          { to: { name: 'pdr' }, text: 'Dettaglio piano di rientro' }
        ]
      }
      return res
    },
    btnPay() {
      let total = this.getResidualAmountToPay()
      if (isNaN(total)) {
        total = 0
      }
      return (
        this.pianoAttivo[0]?.planStatus === consts.STATUS_PDR.CLOSED ||
        !this.pianoAttivo[0]?.isPossibleToPay ||
        total === 0
      )
    }
  },

  beforeCreate() {
    this.$store
      .dispatch('supply/getDetail', {
        clientOwnerCode: this.$route.params.clientOwnerCode,
        supplyCode: this.$route.params.supplyCode
      })
      .then((detail) => {
        this.detail = detail
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  components: {
    TcVars,
    DialogPagaRate
  }
}
</script>
<style lang="scss" scoped>
.pianiDiRientro {
  &__riga-lista {
    margin-bottom: 16px;
    margin-top: 0;
    border-bottom: 1px solid #dfe4ef;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__alert {
    color: $secondari-magenta-100;
  }

  &__desc {
    .HeadingsTitleL {
      font-weight: 500;
    }
  }

  .upper {
    text-transform: uppercase;
  }

  &__summary {
    button {
      width: 100%;
    }
  }

  &__box-lista,
  &__bills-lista {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  &__amount {
    display: flex;
    justify-content: space-between;
  }

  &__bills-included {
    border-radius: 20px;
    margin-top: 40px;
    padding: 32px;
    background-color: $neutri-neutral-10;
  }

  &__box {
    display: flex;
    flex-direction: column;
    padding: 32px;
    padding-bottom: 20px;
    border-radius: 20px;
    background-color: #f8f9fc;
  }
  &__list-bills-included {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dfe4ef;
    &:last-child {
      border-bottom: 0;
    }
  }
}
</style>
